import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import { dayjs } from "../../Services/dateUtil";
import DeleteIcon from "@mui/icons-material/Delete";

const RenderField = ({ label, value, onDelete }) => (
  <Grid container spacing={2} alignItems="center">
    <Grid item sm={4}><b>{label}</b></Grid>
    <Grid item sm={7}>{value}</Grid>
    <Grid item sm={1}>
      <IconButton aria-label="delete" onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </Grid>
  </Grid>
);

const parProperties = (string) => {
  try {
    const json = JSON.parse(string);
    return formatText(JSON.stringify(json, null, "\t"));
  } catch (e) {
    return string;
  }
};

const RenderFields = ({ entry, handleOpenPrompt, handleOpenDatasets, handleOpenResponse, handleDelete }) => (
  <Box p={2}>
    <RenderField label="Engine" value={entry.text + " (" + entry.description + ")"} onDelete={handleDelete} />
  </Box>
);

const EntryDetails = ({ entry, refreshItems }) => {
  const { homeDispatch } = useContext(HomeAppContext);
  const [entryData, setEntryData] = useState(entry);
  const [loading, setLoading] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [openDatasets, setOpenDatasets] = useState(false);
  const [openResponse, setOpenResponse] = useState(false);

  const handleOpenPrompt = () => setOpenPrompt(true);
  const handleClosePrompt = () => setOpenPrompt(false);
  
  const handleOpenDatasets = () => setOpenDatasets(true);
  const handleCloseDatasets = () => setOpenDatasets(false);

  const handleOpenResponse = () => setOpenResponse(true);
  const handleCloseResponse = () => setOpenResponse(false);

  const handleDelete = async () => {
    setLoading(true);
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Removing engine", loading: true }
    });
    api
      .delete(`/api/ai-prompts/DeleteFromEngineGroup/${entryData.id}`)
      .then((response) => {
        console.log(response);
        console.log(response.data);
        if (response.data) {
          homeDispatch({
            type: "NOTIFICATION",
            data: { message: "AI Engine deleted successfuly", type: "success" }
          });
          refreshItems();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error); 
        errorManagement.formErrors(error, homeDispatch);
      });
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!entryData) {
    return <Typography variant="body2">No data available</Typography>;
  }

  return (
    <>
      <Grid container spacing={4} className="Details">
        <Grid item sm={12}>
          <RenderFields entry={entryData} handleOpenPrompt={handleOpenPrompt} handleOpenResponse={handleOpenResponse} handleOpenDatasets={handleOpenDatasets} handleDelete={handleDelete}/>
        </Grid>
      </Grid>

      <Dialog open={openPrompt} onClose={handleClosePrompt} fullWidth maxWidth="md">
        <DialogTitle>Prompt</DialogTitle>
        <DialogContent>
          <Typography component="div" style={{ whiteSpace: "pre-wrap" }}>
            {parProperties(entryData.prompt)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePrompt} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openResponse} onClose={handleCloseResponse} fullWidth maxWidth="md">
        <DialogTitle>Response</DialogTitle>
        <DialogContent>
          <Typography component="div" style={{ whiteSpace: "pre-wrap" }}>
            {parProperties(entryData.response)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResponse} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDatasets} onClose={handleCloseDatasets} fullWidth maxWidth="md">
        <DialogTitle>Datasets</DialogTitle>
        <DialogContent>
          <Typography component="div" style={{ whiteSpace: "pre-wrap" }}>
            {parProperties(entryData.datasets)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDatasets} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EntryDetails;
