import { TextField } from "@mui/material";
import React, { useContext, useState, useRef, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import CustomAutocomplete from "../General/CustomAutocomplete";
import FileUploader from "../General/FileUploader";
import Slider from "@mui/material/Slider";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import { Checkbox, FormControlLabel, FormControl, Select, MenuItem  } from "@mui/material";

export const ClientDescriptionFormMode = {
  NEW: "new",
  EDIT: "edit"
};

export default function Form({
  mode = ClientDescriptionFormMode.NEW,
  entity = null,
  errors = [],
  tags = [],
  engineFamilies = [],
  setEntity,
  loading,
  submitted,
  setSaveDisabled
}) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [variables, setVariables] = useState([]);
  const textFieldRef = useRef(null);
  const [provider, setProvider] = useState(entity.provider || "");
  const [isClientDropdownDisabled, setIsClientDropdownDisabled] = useState(!entity.provider);

  const onInputChange = (event, property) =>
    setEntity({ ...entity, [property]: event.target.value });

  const onDatasetChange = (value, property) => {
    if (value !== "") {
      setEntity({ ...entity, [property]: value });
    }
  };

  const onMultiInputChange = (value, property) => {
    setEntity({ ...entity, [property]: value });
  };

  const onProviderChange = (event) => {
    const selectedProvider = event.target.value;
    setProvider(selectedProvider);
    setIsClientDropdownDisabled(!selectedProvider);

    setEntity({ ...entity, provider: selectedProvider, clientDescriptionClients: [] });
  };

  const onClientChange = (value, property) => {
    setEntity({ ...entity, [property]: value });
  };

  const onTagInputChange = (value, property) => {
    setEntity({ ...entity, [property]: value });
  };

  const onTemperatureChange = (event, newValue) => {
    setEntity({ ...entity, temperature: newValue });
  };

  const onValueChange = (value, property) =>
    setEntity({ ...entity, [property]: value });

  const insertTextAtCursor = (text) => {
    const textField = textFieldRef.current;
    if (!textField) return;

    const startPos = textField.selectionStart;
    const endPos = textField.selectionEnd;

    const newValue =
      textField.value.substring(0, startPos) +
      text +
      textField.value.substring(endPos, textField.value.length);

    textField.value = newValue;
    setEntity({ ...entity, description: newValue });

    textField.selectionStart = startPos + text.length;
    textField.selectionEnd = startPos + text.length;
  };

  return entity === null ? null : (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"space-between"}>

      <Grid item sm={12}>
          <Grid container spacing={12}>
            <Grid item sm={12}>
              <FormControl fullWidth>
                <InputLabel id="provider-label">Provider</InputLabel>
                <Select
                  labelId="provider-label"
                  id="provider"
                  value={provider}
                  label="Provider"
                  onChange={onProviderChange}
                >
                  <MenuItem value="xtrf">XTRF</MenuItem>
                  <MenuItem value="tms">TMS</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

      <Grid item sm={12}>
          <Grid container spacing={12}>
          <Grid item sm={12}>
          <CustomAutocomplete
                  value={entity.clientDescriptionClients}
                  setValue={(value) => onClientChange(value, "clientDescriptionClients")}
                  related={null}
                  onLoading={setSaveDisabled}
                  url={`/api/clients/clients?provider=${entity.provider}`}
                  label={"Clients"}
                  placeholder={"Select a client"}
                  noOptionsText={"No client found"}
                  loadingText={"Searching"}
                  margin={"normal"}
                  multiple={true}
                  liveOptions={true}
                  disabled={isClientDropdownDisabled}
                  error={_.isArray(errors.clientDescriptionClients)}
                  helperText={
                    _.isArray(errors.clientDescriptionClients) && errors.clientDescriptionClients.join()
                  }
                  disableClearable={true}
                />
              </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <Grid container spacing={12}>
            <Grid item sm={12}>
              <TextField
                required
                label="Description Name"
                fullWidth
                value={entity.name}
                onChange={(event) => onInputChange(event, "name")}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <Grid container spacing={12}>
            <Grid item sm={12}>
            <TextField
                label="Description Details"
                fullWidth
                value={entity.description}
                multiline
                rows={4}
                onChange={(event) => onInputChange(event, "description")}
                inputRef={textFieldRef}
              />
            </Grid>
          </Grid>
        </Grid>


        {/* <Grid item sm={4}>
          <CustomAutocomplete
            value={entity.engineFamily}
            setValue={(value) => onEngineFamilyChange(value, "engineFamily")}
            related={null}
            onLoading={setSaveDisabled}
            url={"/api/ai-engines/family/dropdown"}
            label={"Engine Family"}
            placeholder={"Select an engine family"}
            noOptionsText={"No AI engine found"}
            loadingText={"Searching"}
            margin={"normal"}
            multiple={false}
            liveOptions={false}
            error={_.isArray(errors.engineFamilies)}
            helperText={
              _.isArray(errors.engineFamilies) && errors.engineFamilies.join()
            }
            disableClearable={true}
          />
        </Grid> */}

        {/* <Grid item sm={12}>
          <Grid item sm={12}>
            <InputLabel>Prompt File</InputLabel>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={12}>
            <FileUploader
              onFileUploaded={onFileUploaded}
              uploadUrl="/api/files/"
              acceptedFileExtensions=".docx, .doc, .pdf, .txt"
              acceptedFileExtensionsMessage=".docx, .doc, .pdf, .txt"
            ></FileUploader>
          </Grid>
        </Grid> */}

      </Grid>
    </React.Fragment>
  );
}
