import React, { useContext, useEffect } from "react";
import Entry from "./Entry";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import MyDataGrid from "../General/Pagination/MyDataGrid";
import dayjs from "dayjs";

export const periodLogs = [
  {
    label: "Current day",
    from: dayjs().startOf("day"),
    to: dayjs().endOf("day")
  },
  {
    label: "Last day",
    from: dayjs().subtract(1, "day").startOf("day"),
    to: dayjs().subtract(1, "day").endOf("day")
  },
  {
    label: "Current week",
    from: dayjs().startOf("week").startOf("day"),
    to: dayjs().endOf("week").endOf("day")
  },
  {
    label: "Last week",
    from: dayjs().subtract(1, "week").startOf("week").startOf("day"),
    to: dayjs().subtract(1, "week").endOf("week").endOf("day")
  },
  {
    label: "Current month",
    from: dayjs().startOf("month").startOf("day"),
    to: dayjs().endOf("month").endOf("day")
  },
  {
    label: "Last month",
    from: dayjs().subtract(1, "month").startOf("month").startOf("day"),
    to: dayjs().subtract(1, "month").endOf("month").endOf("day")
  },
  {
    label: "Current year",
    from: dayjs().startOf("year").startOf("day"),
    to: dayjs().endOf("year").endOf("day")
  },
  {
    label: "Last year",
    from: dayjs().subtract(1, "year").startOf("year").startOf("day"),
    to: dayjs().subtract(1, "year").endOf("year").endOf("day")
  }
];

export default function DepartmentsList(props) {
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const statusValues = [
    { id: -1, text: "NotFound" },
    { id: 0, text: "NotStarted" },
    { id: 1, text: "Running" },
    { id: 2, text: "Completed" },
    { id: 3, text: "Failed" }
  ];
  const isAdmin =
  homeState.userProfile.role === "Admin" ||
  homeState.userProfile.role === "PowerUser";

  const allowActions = isAdmin;

  const tableOptions = {
    columns: [
      {
        name: "Request Id",
        description: "Request Id",
        sortable: true,
        filter: { enabled: false },
        size: 5
      },
      {
        name: "Prompt Name",
        description: "Prompt Name",
        sortable: true,
        filter: { enabled: false },
        size: 4
      },
      {
        name: "Engine Name",
        description: "Engine Name",
        sortable: true,
        filter: { enabled: false },
        size: 3
      },
      {
        name: "Created At",
        description: "Created At",
        sortable: true,
        filter: { enabled: false },
        size: 2
      },
      {
        name: "Status",
        description: "Status",
        sortable: false,
        filter: { enabled: false },
        size: 1
      }
    ],
    defaultSort: [{ name: "Request Id", order: "-" }],
    additionalFilters: [
      {
        name: "RequestId",
        description: "Request Id",
        filter: {
          enabled: true,
          comparison: "==",
          type: "text",
          value: statusValues,
        },
        size: 4
      },
      {
        name: "promptId",
        description: "Prompt Name",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/ai-prompts/prompt-dropdown",
            multiple: false,
            liveOptions: false,
            checkbox: false
          }
        },
        size: 2
      },
      {
        name: "engineId",
        description: "Engine Name",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/ai-engines/engine-dropdown",
            multiple: false,
            liveOptions: false,
            checkbox: false
          }
        },
        size: 2
      },
      {
        name: "createdAt",
        description: "Created At",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "dateRange",
          defaultValue: 2,
          value: periodLogs,
          options: {
            addNullPeriod: true
          }
        },
        size: 3
      },
      {
        name: "PromptLike",
        description: "Search Prompt",
        filter: {
          enabled: true,
          name: "PromptLike",
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Filter prompt"
        },
        size: 2
      },
      {
        name: "DatasetLike",
        description: "Search Dataset",
        filter: {
          enabled: true,
          name: "DatasetLike",
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Filter dataset"
        },
        size: 2
      },
      {
        name: "ResponseLike",
        description: "Search Response",
        filter: {
          enabled: true,
          name: "ResponseLike",
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Filter response"
        },
        size: 2
      },
      {
        name: "Status",
        description: "Active Status",
        filter: {
          enabled: true,
          comparison: "==",
          type: "select",
          value: statusValues,
        },
        size: 2
      }
    ]
  };

  useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "Prompt Execution History"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <MyDataGrid
            options={tableOptions}
            url={"/api/ArtificialIntelligence/GetAllRequestsWithHistory"}
            label={"AI prompts"}
            renderItem={(item) => <Entry allowActions={allowActions} key={item.id} entry={item} />}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
