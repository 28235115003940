import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Box
} from '@mui/material';

const ShareDialog = ({ open, onClose, onSave, currentSharingOption }) => {
  const [selectedOption, setSelectedOption] = useState(currentSharingOption);

  useEffect(() => {
    if (currentSharingOption) {
      setSelectedOption(currentSharingOption);
    }
  }, [currentSharingOption]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSave = () => {
    onSave(selectedOption);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Share Settings</DialogTitle>
      <DialogContent>
        <Typography>Select the sharing option:</Typography>
        <RadioGroup
          value={selectedOption}
          onChange={handleOptionChange}
          aria-label="sharing options"
        >
          <FormControlLabel value="private" control={<Radio />} label="Private" />
          <Box pl={4} mb={2}>
            <Typography variant="body2" color="textSecondary">
              This is the default state and means that nobody else can see this prompt, other than the creator.
            </Typography>
          </Box>

          <FormControlLabel value="public-read-only" control={<Radio />} label="Public Read-Only" />
          <Box pl={4} mb={2}>
            <Typography variant="body2" color="textSecondary">
              This prompt is shared, but only the creator can edit it.
            </Typography>
          </Box>

          <FormControlLabel value="public" control={<Radio />} label="Public" />
          <Box pl={4} mb={2}>
            <Typography variant="body2" color="textSecondary">
              This prompt can be edited by anyone. When they save it, all changes will be saved back and available to all users.
            </Typography>
          </Box>
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDialog;
