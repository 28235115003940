import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { HomeAppContext } from "../Home";
import Slide from "@mui/material/Slide";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import Form, { ClientDesignFormMode } from "./Form";
import { set } from "lodash";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialEntry = {
  provider: "",
  clientDesignClients: [],
  designName: "",
  aiEngines: [],
  clientDesign: "",
  design: "",
  files: [],
  tags: [],
  dataset: "",
  engineFamily: {
    id: "1",
    text: "ChatGPT"
  },
  temperature: 0.0,
  isJson: false,
  inputType: "",
  ocrOption: "",
  fileId: 0,
};

export default function Create({ open, setOpen, onCreate }) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [internalEntry, setInternalEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [designNeedsReviewed, setdesignNeedsReviewed] = useState(false);
  const [tagsFromApi, setTagsFromApi] = useState([]);
  const [familiesFromApi, setFamiliesFromApi] = useState([]);

  React.useEffect(() => {
    if (open) {
      setSubmitted(false);
      setErrors([]);
      setInternalEntry(initialEntry);
      setLoading(false);
      //setdesignNeedsReviewed(true);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
    setInternalEntry(initialEntry);
  };

  const handleReviewdesign = () => {
    const reviewDto = {
      clientDesignClients: internalEntry.clientDesignClients.map((e) => e.id),
      designName: internalEntry.designName,
      aiEngines: internalEntry.aiEngines.map((e) => e.id),
      clientDesign: internalEntry.clientDesign,
      design: internalEntry.design,
      tags: internalEntry.tags,
      temperature: internalEntry.temperature,
      isJson: internalEntry.isJson,
      provider: internalEntry.provider,
    };
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Reviewing Style Guide", loading: true }
    });
    api
      .post("/api/client-designs/review-clientDesign", reviewDto)
      .then((r) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "Reviewing Style Guide success",
            type: "success"
          }
        });
        setdesignNeedsReviewed(false);
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  const handleCloseSave = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Saving Style Guide", loading: true }
    });
    setSubmitted(true);
    setLoading(true);
    const newdesign = {
      name: internalEntry.name,
      design: internalEntry.design,
      clientDesignClients: internalEntry.clientDesignClients.map((e) => e.id),
      inputType: internalEntry.inputType,
      ocrOption: internalEntry.ocrOption,
      fileId: internalEntry.fileId ?? 0,
      provider: internalEntry.provider,
      designType: internalEntry.designType,
      language: internalEntry.language,
    };
    console.log(newdesign);
    api
      .post(`/api/client-designs`, newdesign)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: 'Saved Style Guide "' + response.data.designName + '"',
            type: "success"
          }
        });
        setOpen(false);
        setLoading(false);
        setInternalEntry(initialEntry);
        onCreate();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  return internalEntry === null ? null : (
    <Dialog
      maxWidth={"lg"}
      fullWidth
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-create-entry"
    >
      <DialogTitle id="alert-dialog-create-entry">
        {"New Style Guide"}
      </DialogTitle>
      <DialogContent>
        <Form
          mode={ClientDesignFormMode.NEW}
          entity={internalEntry}
          tags={tagsFromApi}
          engineFamilies={familiesFromApi}
          errors={errors}
          setEntity={setInternalEntry}
          loading={loading}
          submitted={submitted}
          setSaveDisabled={setSaveDisabled}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        {designNeedsReviewed === true ? (
          <Button
            onClick={handleReviewdesign}
            variant="outlined"
            color="primary"
          >
            Review Style Guide
          </Button>
        ) : (
          <Button
            onClick={handleCloseSave}
            color="primary"
            variant="outlined"
            autoFocus
            disabled={loading || saveDisabled}
          >
            Save Style Guide
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
