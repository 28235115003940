import { TextField } from "@mui/material";
import React, { useContext, useState, useRef, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import CustomAutocomplete from "../General/CustomAutocomplete";
import FileUploader from "../General/FileUploader";
import Slider from "@mui/material/Slider";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import { Checkbox, FormControlLabel, useTheme, FormControl, Select, MenuItem } from "@mui/material";
import { Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DatasetIcon from '@mui/icons-material/Dataset';

export const AIPromptFormMode = {
  NEW: "new",
  EDIT: "edit"
};

export default function Form({
  mode = AIPromptFormMode.NEW,
  entity = null,
  errors = [],
  tags = [],
  engineFamilies = [],
  setEntity,
  loading,
  submitted,
  setSaveDisabled,
  readOnly = false
}) {
  const theme = useTheme();
  const { homeDispatch } = useContext(HomeAppContext);
  const [variables, setVariables] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedDescriptions, setSelectedDescriptions] = useState([]);
  const [selectedDesigns, setSelectedDesigns] = useState([]);
  const textFieldRef = useRef(null);
  const countRef = useRef(0);
  const [provider, setProvider] = useState(entity.provider || "");
  const [isClientDropdownDisabled, setIsClientDropdownDisabled] = useState(!entity.provider);
  const [isClientDescDropdownDisabled, setIsClientDescDropdownDisabled] = useState(true);
  const [clientDescriptions, setClientDescriptions] = useState([]);
  const [clientDesigns, setClientDesigns] = useState([]);
  
  console.log("Entity", clientDesigns);
  const genericDesigns = clientDesigns.filter((design) => design.prepend === "0");
  const languageSpecificDesigns = clientDesigns.filter((design) => design.prepend === "1");

  const onProviderChange = (event) => {
    const selectedProvider = event.target.value;
    setProvider(selectedProvider);
    setIsClientDropdownDisabled(!selectedProvider);

    setEntity({ ...entity, provider: selectedProvider, aiPromptClients: [], clientDescriptions: [], clientDesigns: [] });
    setIsClientDescDropdownDisabled(true);
  };

  const onInputChange = (event, property) =>
    setEntity({ ...entity, [property]: event.target.value });

  const onDatasetChange = (value, property) => {
    if (value !== "") {
      setEntity({ ...entity, [property]: value });
    }
  };

  const onMultiInputChange = (value, property) => {
    setEntity({ ...entity, [property]: value });
  };

  const onClientChange = async (value, property) => {
    setSelectedClients(value.map(client => client.id));
    setEntity({ ...entity, [property]: value });

    if (value.length > 0) {
      fetchClientDescriptionsAndDesigns(value.map(client => client.id));
    } else {
      setClientDescriptions([]);
      setClientDesigns([]);
    }
  };

  const fetchClientDescriptionsAndDesigns = async (clientIds) => {
    try {
      const descriptionsResponse = await api.get(
        `/api/ai-engines/clientDescriptions?ids=${clientIds.join(",")}`
      );
      setClientDescriptions(descriptionsResponse.data);
  
      const designsResponse = await api.get(
        `/api/ai-engines/clientDesigns?ids=${clientIds.join(",")}`
      );
      setClientDesigns(designsResponse.data);
    } catch (error) {
      errorManagement.formErrors(error, homeDispatch);
    }
  };  

  const onDescriptionChange = (value, property) => {
    // Get the current prompt
    let updatedPrompt = entity.prompt;
  
    // Extract the current descriptions from the prompt
    const currentDescriptions = selectedDescriptions.map(id => 
      entity.clientDescriptions.find(desc => desc.id === id)?.description || ''
    );
  
    // Get the new descriptions
    const newDescriptions = value.map(client => client.description);
  
    // Remove old descriptions that are no longer selected
    currentDescriptions.forEach(description => {
      if (!newDescriptions.includes(description)) {
        updatedPrompt = updatedPrompt.replace(description, "").trim();
      }
    });
  
    // Add new descriptions that are not already in the prompt
    newDescriptions.forEach(description => {
      if (!updatedPrompt.includes(description)) {
        updatedPrompt = `${updatedPrompt} ${description}`.trim();
      }
    });
  
    // Update the state with the new descriptions and prompt
    if ((mode === AIPromptFormMode.NEW || entity.clientDescriptions.length === 0 || countRef.current >= 2) ||
        (mode === AIPromptFormMode.EDIT && entity.clientDesigns.length === 0 && entity.clientDescriptions.length >= 0 && countRef.current === 1)) {
      setSelectedDescriptions(value.map(client => client.id));
      setEntity({ 
        ...entity, 
        [property]: value,  // Update the selected descriptions
        prompt: updatedPrompt // Update the prompt with the new descriptions appended
      });
    }

    countRef.current += 1;
  };

  const onDesignChange = (value, property) => {
    // Get the current prompt
    let updatedPrompt = entity.prompt;
  
    // Extract the current designs from the prompt
    const currentDesigns = selectedDesigns.map(id => 
      entity.clientDesigns.find(desc => desc.id === id)?.description || ''
    );
  
    // Get the new designs
    const newDesigns = value.map(client => client.description);
  
    // Remove old designs that are no longer selected
    currentDesigns.forEach(description => {
      if (!newDesigns.includes(description)) {
        updatedPrompt = updatedPrompt.replace(description, "").trim();
      }
    });
  
    // Add new designs that are not already in the prompt
    newDesigns.forEach(description => {
      if (!updatedPrompt.includes(description)) {
        updatedPrompt = `${updatedPrompt} ${description}`.trim();
      }
    });
  
    // Update the state with the new designs and prompt
    if ((mode === AIPromptFormMode.NEW || entity.clientDesigns.length === 0 || countRef.current >= 2) ||
        (mode === AIPromptFormMode.EDIT && entity.clientDescriptions.length === 0 && entity.clientDesigns.length >= 0 && countRef.current === 1)) {
      setSelectedDesigns(value.map(client => client.id));
      setEntity({ 
        ...entity, 
        [property]: value,  // Update the selected designs
        prompt: updatedPrompt // Update the prompt with the new designs appended
      });
    }

    countRef.current += 1;
  };  

  const onTagInputChange = (value, property) => {
    setEntity({ ...entity, [property]: value });
  };

  const onTemperatureChange = (event, newValue) => {
    setEntity({ ...entity, temperature: newValue });
  };

  const handleClientDescriptionChange = (value) => {
    console.log(value);
    onClientChange(value.map(v => v.text), "clientDescriptions")
    // Store descriptions separately if needed
    const descriptions = value.map(v => v.description); 
    // Use descriptions elsewhere
  };

  const onValueChange = (value, property) =>
    setEntity({ ...entity, [property]: value });

  const getDatasetVariables = (datasetId) => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Fetching dataset variables", loading: true }
    });
    api
      .get(`/api/datasets/variables/${datasetId}/dropdown`)
      .then((r) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "Dataset variables fetched successfuly",
            type: "success"
          }
        });
        setVariables(r.data);
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  useEffect(() => {
    if (entity.dataset && entity.dataset.id !== 0 && entity.dataset !== "") {
      getDatasetVariables(entity.dataset.id);
    }
  }, [entity.dataset]);

  const insertTextAtCursor = (text) => {
    const textField = textFieldRef.current;
    if (!textField) return;

    const startPos = textField.selectionStart;
    const endPos = textField.selectionEnd;

    const newValue =
      textField.value.substring(0, startPos) +
      text +
      textField.value.substring(endPos, textField.value.length);

    textField.value = newValue;
    setEntity({ ...entity, prompt: newValue });

    textField.selectionStart = startPos + text.length;
    textField.selectionEnd = startPos + text.length;
  };

  return entity === null ? null : (
    <React.Fragment>
      <Grid container spacing={4}>

        {/* Left side with only the prompt text field */}
        <Grid item sm={6}>
        <TextField
        disabled={readOnly}
      label="Type your prompt..."
      fullWidth
      value={entity.prompt}
      multiline
      rows={28}
      onChange={(event) => onInputChange(event, "prompt")}
      inputRef={textFieldRef}
      placeholder="Start typing your AI prompt here..."
      variant="outlined"
      sx={{
        "& .MuiOutlinedInput-root": {
          backgroundColor: theme.palette.mode === "dark" ? "#424242" : "#fff",
          borderRadius: "10px",
          borderColor: theme.palette.mode === "dark" ? "#616161" : "#e0e0e0",
          boxShadow:
            theme.palette.mode === "dark"
              ? "0 3px 6px rgba(255, 255, 255, 0.1)"
              : "0 3px 6px rgba(0, 0, 0, 0.05)",
          transition: "all 0.3s ease",
          "& fieldset": {
            borderColor: theme.palette.mode === "dark" ? "#616161" : "#e0e0e0",
          },
          "&:hover fieldset": {
            borderColor: theme.palette.mode === "dark" ? "#fff" : "#757575",
          },
          "&.Mui-focused fieldset": {
            borderColor: theme.palette.primary.main,
            boxShadow:
              theme.palette.mode === "dark"
                ? "0 0 8px rgba(255, 255, 255, 0.25)"
                : "0 0 8px rgba(63, 81, 181, 0.25)",
          },
        },
        "& .MuiInputLabel-root": {
          color: theme.palette.mode === "dark" ? "#bdbdbd" : "#757575",
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: theme.palette.primary.main,
        },
        "& .MuiInputBase-input": {
          fontSize: "16px",
          lineHeight: "1.5",
          padding: "12px",
          color: theme.palette.mode === "dark" ? "#fff" : "#000",
        },
        "& .MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.85)",
        },
      }}
    />
        </Grid>

        {/* Right side with all other inputs */}
        <Grid item sm={6}>
          <Grid container spacing={3}>

            <Grid item sm={12}>
            <TextField
                required
                label="Prompt Name"
                fullWidth
                value={entity.promptName}
                onChange={(event) => onInputChange(event, "promptName")}
                disabled={readOnly}
              />
            </Grid>

            <Grid item sm={12}>
            <CustomAutocomplete
              value={entity.aiEngines}
              setValue={(value) => onMultiInputChange(value, "aiEngines")}
              related={null}
              onLoading={setSaveDisabled}
              url={`/api/ai-engines/dropdown`}
              label={"AI Engines"}
              placeholder={"Select an engine"}
              noOptionsText={"No AI engine found"}
              loadingText={"Searching"}
              margin={"normal"}
              multiple={true}
              liveOptions={false}
              error={_.isArray(errors.aiEngines)}
              helperText={
                _.isArray(errors.aiEngines) && errors.aiEngines.join()
              }
              disableClearable={true}
              disabled={readOnly}
            />
            </Grid>

            <Grid item sm={12}>
              <FormControl fullWidth>
                <InputLabel disabled={readOnly} id="provider-label">Client Provider</InputLabel>
                <Select
                  labelId="provider-label"
                  id="provider"
                  value={provider}
                  label="Provider"
                  onChange={onProviderChange}
                  disabled={readOnly}
                >
                  <MenuItem value="xtrf">XTRF</MenuItem>
                  <MenuItem value="tms">TMS</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12}>
            <CustomAutocomplete
                  value={entity.aiPromptClients}
                  setValue={(value) => onClientChange(value, "aiPromptClients")}
                  related={null}
                  onLoading={setSaveDisabled}
                  url={`/api/clients/clients?provider=${entity.provider}`}
                  label={"Clients"}
                  placeholder={"Select a client"}
                  noOptionsText={"No client found"}
                  loadingText={"Searching"}
                  margin={"normal"}
                  multiple={true}
                  liveOptions={true}
                  disabled={isClientDropdownDisabled}
                  error={_.isArray(errors.aiPromptClients)}
                  helperText={
                    _.isArray(errors.aiPromptClients) && errors.aiPromptClients.join()
                  }
                  disableClearable={true}
                  disabled={readOnly}
                />
            </Grid>

            <Grid item sm={12}>
              <InputLabel disabled={readOnly}>Client Descriptions</InputLabel>
              {clientDescriptions.length > 0 ? (
                <Grid container spacing={1}>
                  {clientDescriptions.map((description) => (
                    <Grid item key={description.id}>
                      <Chip
                        disabled={readOnly}
                        label={description.text}
                        size="medium"
                        icon={<DescriptionIcon style={{ color: '#fff' }} />}
                        onClick={() => insertTextAtCursor(`%${description.text}%`)}
                        style={{
                          backgroundColor: '#1976d2',
                          color: '#fff',
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  {selectedClients.length === 0
                    ? 'Please select a client to see client descriptions.'
                    : 'No client descriptions found for the selected client.'}
                </Typography>
              )}
            </Grid>

            <Grid item sm={12}>
              <InputLabel disabled={readOnly}>Generic Style Guides</InputLabel>
              {genericDesigns.length > 0 ? (
                <Grid container spacing={1}>
                  {genericDesigns.map((design) => (
                    <Grid item key={design.id}>
                      <Chip
                        disabled={readOnly}
                        label={design.text}
                        size="medium"
                        icon={<DesignServicesIcon style={{ color: '#fff' }} />}
                        onClick={() => insertTextAtCursor(`%${design.text}%`)}
                        style={{
                          backgroundColor: '#388e3c',
                          color: '#fff',
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  {selectedClients.length === 0
                    ? 'Please select a client to see generic style guides.'
                    : 'No generic style guides found for the selected client.'}
                </Typography>
              )}
            </Grid>

            <Grid item sm={12}>
              <InputLabel disabled={readOnly}>Language-Specific Style Guides</InputLabel>
              {languageSpecificDesigns.length > 0 ? (
                <Grid container spacing={1}>
                    <Grid item key={0}>
                      <Chip
                        disabled={readOnly}
                        label={"srcLangStyleGuide"}
                        size="medium"
                        icon={<DesignServicesIcon style={{ color: '#fff' }} />}
                        onClick={() => insertTextAtCursor("%srcLangStyleGuide%")}
                        style={{
                          backgroundColor: '#388e3c',
                          color: '#fff',
                        }}
                      />
                    </Grid>
                    <Grid item key={1}>
                      <Chip
                        disabled={readOnly}
                        label={"trgLangStyleGuide"}
                        size="medium"
                        icon={<DesignServicesIcon style={{ color: '#fff' }} />}
                        onClick={() => insertTextAtCursor("%trgLangStyleGuide%")}
                        style={{
                          backgroundColor: '#388e3c',
                          color: '#fff',
                        }}
                      />
                    </Grid>
                </Grid>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  {selectedClients.length === 0
                    ? 'Please select a client to see language-specific style guides.'
                    : 'No language-specific style guides found for the selected client.'}
                </Typography>
              )}
            </Grid>

            <Grid item sm={12}>
                    <CustomAutocomplete
                      disabled={readOnly}
                      value={entity.dataset}
                      setValue={(value) => onDatasetChange(value, "dataset")}
                      related={null}
                      onLoading={setSaveDisabled}
                      url={"/api/datasets/dropdown"}
                      label={"Datasets"}
                      placeholder={"Select a dataset"}
                      noOptionsText={"No datasets found"}
                      loadingText={"Searching"}
                      multiple={false}
                      liveOptions={false}
                      error={_.isArray(errors.dataset)}
                      helperText={
                        _.isArray(errors.dataset) && errors.dataset.join()
                      }
                      disableClearable={true}
                    />
                </Grid>
                <Grid item sm={12}>
                  <InputLabel disabled={readOnly}>Dataset Variables</InputLabel>
                  {entity.dataset && variables.length > 0 ? (
                    <Grid container spacing={1}>
                      {variables.map((variable) => (
                        <Grid item key={variable.id}>
                          <Chip
                            disabled={readOnly}
                            label={variable.text}
                            size="medium"
                            icon={<DatasetIcon style={{ color: '#fff' }} />}
                            onClick={() => insertTextAtCursor(`%${variable.text}%`)}
                            style={{
                              backgroundColor: '#f57c00',
                              color: '#fff',
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      {entity.dataset
                        ? 'No variables found in the selected dataset.'
                        : 'Please select a dataset to see variables.'}
                    </Typography>
                  )}
                </Grid>

                <Grid item sm={12}>
            <Autocomplete
              disabled={readOnly}
              freeSolo={true}
              multiple={true}
              disableClearable
              value={entity.tags}
              onChange={(event, value) => onTagInputChange(value, "tags")}
              options={tags.map((tag) => tag.text)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Prompt Tags"
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
            </Grid>

            <Grid item sm={12}>
            <TextField
                disabled={readOnly}
                label="Prompt Description"
                fullWidth
                value={entity.description}
                multiline
                rows={4}
                onChange={(event) => onInputChange(event, "description")}
              />
            </Grid>







            <Grid item sm={12}>
            <InputLabel disabled={readOnly}>Temperature: {entity.temperature}</InputLabel>

            <Slider
              disabled={readOnly}
              value={entity.temperature}
              min={0.0}
              step={0.1}
              max={1}
              onChange={onTemperatureChange}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
            />
            </Grid>

              

<Grid item sm={12}>
<FormControlLabel
              control={
                <Checkbox
                  disabled={readOnly}
                  checked={entity.isJson}
                  onChange={(event) =>
                    onValueChange(event.target.checked, "isJson")
                  }
                />
              }
              label="Output as JSON"
            />
</Grid>

          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
