import React, { useContext } from "react";

import AddBoxIcon from "@mui/icons-material/AddBox";
import Button from "@mui/material/Button";
import ConfirmDialog from "../General/ConfirmDialog";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import MyDataGrid from "../General/Pagination/MyDataGrid";
import Edit from "./Edit";
import Entry from "./Entry";
import Create from "./Create";
import dayjs from "dayjs";
import { Sync } from "mdi-material-ui";

export const periodLogs = [
  {
    label: "Current month",
    from: dayjs().startOf("month").startOf("day"),
    to: dayjs().endOf("month").endOf("day")
  },
  {
    label: "Last month",
    from: dayjs().subtract(1, "month").startOf("month").startOf("day"),
    to: dayjs().subtract(1, "month").endOf("month").endOf("day")
  }
];

export default function List(props) {
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openRemove, setOpenRemove] = React.useState(false);
  const [removeParams, setRemoveParams] = React.useState(false);
  const [currentEntry, setCurrentEntry] = React.useState(null);
  const [refresh, setRefresh] = React.useState(0);

  const isAdmin =
    homeState.userProfile.role === "Admin" ||
    homeState.userProfile.role === "PowerUser" ||
    homeState.userProfile.role === "User";

  const allowActions = isAdmin;

  const handleClickCreate = (event) => {
    setOpenCreate(true);
  };

  const handleClickOwner = async () => {
    try {
      await api.get(`/api/ai-assistants/SyncOwner`);
    } 
    catch (error) {
      console.error('Error SyncOwner:', error);
    }
  }; 

  const handleClickModel = async () => {
    try {
      await api.get(`/api/ai-assistants/SyncModel`);
    } 
    catch (error) {
      console.error('Error SyncModel:', error);
    }
  }; 

  const removeAssistant = (params) => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Removing assistant", loading: true }
    });
    api
      .delete(`/api/ai-assistants/${params.id}`)
      .then((response) => {
        if (response.data) {
          homeDispatch({
            type: "NOTIFICATION",
            data: {
              message: "AI Assistant deleted successfuly",
              type: "success"
            }
          });
        }
        refreshItems();
      })
      .catch((error) => {
        errorManagement.formErrors(error, homeDispatch);
      });
  };

  const tableOptions = {
    columns: [
      {
        name: "Name",
        description: "Assistant Name",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 4
      },
      {
        name: "Model",
        description: "Model",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 4
      },
      {
        name: "CreatedAt",
        description: "Created At",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 3
      },
      {
        name: "Actions",
        description: "",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      }
    ],
    defaultSort: [{ name: "CreatedAt", order: "-" }],
    additionalFilters: [
      {
        name: "Name",
        description: "Assistant Name",
        filter: {
          enabled: true,
          name: "NameLike",
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Filter assistant name"
        },
        size: allowActions ? 3 : "auto"
      },
      {
        name: "Model",
        description: "Model",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/ai-assistants/deployments/dropdown",
            multiple: true,
            liveOptions: false,
            checkbox: false
          }
        },
        size: 3
      },
      {
        name: "Period",
        description: "Created At",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "dateRange",
          defaultValue: 2,
          value: periodLogs,
          options: {
            addNullPeriod: true
          }
        },
        size: 3
      }
    ]
  };

  React.useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "AI Assistants"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  const handleClickEdit = (event, entry) => {
    setCurrentEntry(entry);
    setOpenEdit(true);
  };

  const handleClickRemove = (event, entry) => {
    setCurrentEntry(entry);
    setRemoveParams({ id: entry.id });
    setOpenRemove(true);
  };

  const cancelRemove = () => {
    setRemoveParams(null);
  };

  const refreshItems = () => {
    setRefresh(refresh + 1);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"space-between"}>
        {allowActions && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickCreate}
              startIcon={<AddBoxIcon />}
            >
              New AI Assistant
            </Button>
          </Grid>
          
        )}
        <Grid item xs={12}>
          <MyDataGrid
            options={tableOptions}
            url={"/api/ai-assistants"}
            refresh={refresh}
            label={"AI Assistants"}
            renderItem={(item, index) => (
              <Entry
                key={index}
                entry={item}
                allowActions={allowActions}
                isAdmin={isAdmin}
                onEdit={handleClickEdit}
                onRemove={handleClickRemove}
              />
            )}
          />
        </Grid>
      </Grid>
      {allowActions && (
        <React.Fragment>
          <Edit
            open={openEdit}
            setOpen={setOpenEdit}
            entry={currentEntry}
            onEdit={refreshItems}
          />
          <Create
            open={openCreate}
            setOpen={setOpenCreate}
            onCreate={refreshItems}
          />
          {currentEntry && (
            <ConfirmDialog
              open={openRemove}
              setOpen={setOpenRemove}
              title={"Remove " + currentEntry.name + "?"}
              message={
                'Are you sure that you want to remove the AI Assistant "' +
                currentEntry.name +
                '"' +
                "?"
              }
              onCloseSuccess={removeAssistant}
              successParams={removeParams}
              onClose={cancelRemove}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
