import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Typography 
} from '@mui/material';
import api from '../../Services/api';
import { useContext } from 'react';
import { HomeAppContext } from '../Home';

export default function ChangeShareTypeDialog({ open, setOpen, assistantId, currentShareType, onShareTypeChanged }) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [selectedShareType, setSelectedShareType] = useState(currentShareType);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setLoading(true);

    const dto = {
      shareType: selectedShareType,
    };

    api.put(`/api/ai-assistants/${assistantId}/sharetype`, dto)
      .then(() => {
        homeDispatch({
          type: 'NOTIFICATION',
          data: { message: 'Share type updated successfully', type: 'success' },
        });
        setOpen(false);
        onShareTypeChanged(selectedShareType);
      })
      .catch((error) => {
        homeDispatch({
          type: 'NOTIFICATION',
          data: { message: 'Error updating share type', type: 'error' },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Change Share Type</DialogTitle>
      <DialogContent>
          <Typography>Select the sharing option:</Typography>
          <RadioGroup
            aria-label="sharing options"            
            value={selectedShareType}
            onChange={(e) => setSelectedShareType(parseInt(e.target.value))}
          >

          <FormControlLabel value={0} control={<Radio />} label="Private" />
          <Box pl={4} mb={2}>
            <Typography variant="body2" color="textSecondary">
              This is the default state and means that nobody else can see this assistant, other than the creator.
            </Typography>
          </Box>

          <FormControlLabel value={1} control={<Radio />} label="Public Read-Only" />
          <Box pl={4} mb={2}>
            <Typography variant="body2" color="textSecondary">
              This assistant is shared, but only the creator can edit it.
            </Typography>
          </Box>

          <FormControlLabel value={2} control={<Radio />} label="Public" />
          <Box pl={4} mb={2}>
            <Typography variant="body2" color="textSecondary">
              This assistant can be edited by anyone. When they save it, all changes will be saved back and available to all users.
            </Typography>
          </Box>

          </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="outlined" disabled={loading}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
