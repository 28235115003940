import Form, { AIPromptFormMode, UserFormMode } from "./Form";
import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { HomeAppContext } from "../Home";
import Slide from "@mui/material/Slide";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Edit({ open, setOpen, entry, onEdit }) {
  console.log("Edit entry", entry);
  const { homeState, homeDispatch } = useContext(HomeAppContext);

  const [internalEntry, setInternalEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState([]);
  const [promptNeedsReviewed, setPromptNeedsReviewed] = useState(false);
  const [tagsFromApi, setTagsFromApi] = useState([]);
  const [familiesFromApi, setFamiliesFromApi] = useState([]);
  const [readOnly, setReadOnly] = useState(false);

  React.useEffect(() => {
    if (entry !== null && open) {
      setInternalEntry(entry);
      setErrors([]);
      setLoading(false);
      setSubmitted(false);
      // setPromptNeedsReviewed(true);
      getTags();
      getEngineFamilies();
      setReadOnly((entry.createdBy.id !== homeState.userProfile.userId) && (entry.shareType === 1));
    } else {
      setInternalEntry(null);
    }
  }, [entry, open]);

  const getTags = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Fetching prompt tags", loading: true }
    });
    api
      .get("/api/ai-prompts/tags/dropdown")
      .then((r) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Prompt tags fetched successfuly", type: "success" }
        });
        setTagsFromApi(r.data);
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  const getEngineFamilies = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Fetching engine families", loading: true }
    });
    api
      .get("/api/ai-engines/family/dropdown")
      .then((r) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "Engine families fetched successfuly",
            type: "success"
          }
        });
        setFamiliesFromApi(r.data);
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
    setInternalEntry(null);
  };

  const handleReviewPrompt = () => {
    console.log(internalEntry);
    const reviewDto = {
      aiPromptClients: internalEntry.aiPromptClients.map((e) => e.id),
      clientDescriptions: internalEntry.clientDescriptions.map((e) => e.id),
      clientDesigns: internalEntry.clientDesigns.map((e) => e.id),
      promptName: internalEntry.promptName,
      aiEngines: internalEntry.aiEngines.map((e) => e.id),
      prompt: internalEntry.prompt,
      description: internalEntry.description,
      tags: internalEntry.tags,
      temperature: internalEntry.temperature,
      isJson: internalEntry.isJson,
      provider: internalEntry.provider
    };
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Reviewing AI prompt", loading: true }
    });
    api
      .post("/api/ai-prompts/review-prompt", reviewDto)
      .then((r) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "Reviewing AI prompt success",
            type: "success"
          }
        });
        setPromptNeedsReviewed(false);
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  const handleCloseSave = () => {
    if (readOnly) return;
    console.log(internalEntry);
    const updatePrompt = {
      id: internalEntry.id,
      aiPromptClients: internalEntry.aiPromptClients.map((e) => e.id),
      clientDescriptions: internalEntry.clientDescriptions.map((e) => e.id),
      clientDesigns: internalEntry.clientDesigns.map((e) => e.id),
      promptName: internalEntry.promptName,
      aiEngines: internalEntry.aiEngines.map((e) => e.id),
      prompt: internalEntry.prompt,
      description: internalEntry.description,
      //files: internalEntry.files.map((e) => e.id),
      tags: internalEntry.tags,
      dataset: internalEntry.dataset,
      temperature: internalEntry.temperature,
      isJson: internalEntry.isJson,
      provider: internalEntry.provider
    };

    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Updating " + internalEntry.promptName, loading: true }
    });
    setSubmitted(true);
    setLoading(true);
    api
      .put(`/api/ai-prompts/${updatePrompt.id}`, updatePrompt)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: internalEntry.promptName + " updated",
            type: "success"
          }
        });
        setOpen(false);
        setLoading(false);
        onEdit();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  return internalEntry === null ? null : (
    <Dialog
      maxWidth={"lg"}
      fullWidth
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-edit-entry"
    >
      <DialogTitle id="alert-dialog-edit-entry"> {readOnly ? "View AI Prompt Details" : "Edit AI Prompt"} </DialogTitle>
      <DialogContent>
      {readOnly && (
          <Box
            sx={{
              padding: 2,
              border: "1px solid",
              borderColor: "warning.main",
              borderRadius: "8px",
              backgroundColor: "warning.mainlight",
              marginBottom: 2,
              color: "warning.dark",
              textAlign: "center"
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              This prompt is public read-only, so you cannot edit it.
            </Typography>
          </Box>
        )}
        <Form
          mode={AIPromptFormMode.EDIT}
          entity={internalEntry}
          tags={tagsFromApi}
          engineFamilies={familiesFromApi}
          errors={errors}
          setEntity={setInternalEntry}
          loading={loading}
          submitted={submitted}
          setSaveDisabled={setSaveDisabled}
          readOnly={readOnly}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        {(promptNeedsReviewed && !readOnly) && (
          <Button
            onClick={handleReviewPrompt}
            variant="outlined"
            color="primary"
          >
            Review Prompt
          </Button>
        )}
        {(!promptNeedsReviewed && !readOnly) && (          
          <Button
            onClick={handleCloseSave}
            color="primary"
            variant="outlined"
            autoFocus
            disabled={loading || saveDisabled}
          >
            Save Prompt
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
