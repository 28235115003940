import {
  Checkbox,
  FormControlLabel,
  TextField,
  LinearProgress
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import FileUploader from "../General/FileUploader";
import Button from "@mui/material/Button";
import api from "../../Services/api";

export default function AssistantChatForm({
  entity = null,
  errors = [],
  result = null,
  setEntity,
  loading,
  submitted,
  setSaveDisabled,
  canUpload
}) {
  const [fileCountRemaining, setFileCountRemaining] = useState(1);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const onInputChange = (event, property) => {
    setEntity({ ...entity, [property]: event.target.value });
  };

  const onFileUploaded = (value) => {
    const uploadedFiles = value.map((item) => item.id);

    setEntity({
      ...entity,
      fileIds: uploadedFiles
    });

    setFileCountRemaining(1 - uploadedFiles.length);
  };

  const handleFile = async (value) => {
    try {
      const resultDto = {
        path: value,
      };
      
      const response = await api.post(`/api/ai-assistants/download-file`, resultDto, {
        responseType: 'blob',
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', result.nameFile);
      document.body.appendChild(link);
      link.click();
      
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Failed to fetch download URL", error);
    }
  };  

  return entity === null ? null : (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item sm={12}>
          <Grid item sm={12}>
            <FileUploader
              onFileUploaded={onFileUploaded}
              uploadUrl="/api/files"
              acceptedFileExtensions=".c, .cs, .cpp, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .js, .sh, .ts"
              acceptedFileExtensionsMessage={".c, .cs, .cpp, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .js, .sh, .ts"}
              maxSize={209720000}
            ></FileUploader>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={12}>
            <TextField
              label="Text/Prompt"
              fullWidth
              value={entity.prompt}
              multiline
              rows={4}
              onChange={(event) => onInputChange(event, "prompt")}
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          {loading ? <LinearProgress /> : null}
        </Grid>
        <Grid item sm={12}>
          {" "}
        </Grid>
        {result && result !== null ? (
          <>
            <Grid item sm={12}>
              <InputLabel>Assistant Response:</InputLabel>
            </Grid>
            <Grid item sm={12}>
              {result.aiResponse}
            </Grid>
            {result.isFile && (
              <Grid item sm={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleFile(result.pathFile)}
                  target="_blank"
                  download
                >
                  Download File
                </Button>
              </Grid>
            )}
          </>
        ) : null}
      </Grid>
    </React.Fragment>
  );
}
