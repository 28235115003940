import { Collapse, TableCell, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import EntryDetails from "./EntryDetails";
import EntryDetailsFailed from "./EntryDetailsFailed";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { dayjs } from "../../Services/dateUtil";
import Box from "@mui/material/Box";
import api from "../../Services/api";

const Entry = ({ entry }) => {
  const [show, setShow] = useState(false);
  const [executionHistories, setExecutionHistories] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [entryDataset, setEntryDataset] = useState(null);
  const [entryPromptId, setEntryPromptId] = useState(null);

  const toggle = async () => {
    setShow(prevShow => !prevShow);
    
    if (!executionHistories && !show) {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`/api/ArtificialIntelligence/GetPromptExecutionHistoriesById?id=${entry.id}`);
        setExecutionHistories(response.data);
        setEntryDataset(entry.datasets);
        setEntryPromptId(entry.promptId);
      } catch (err) {
        setError("Failed to fetch histories");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item>
              <IconButton onClick={toggle} size="small">
                {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              {entry.requestId}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>{entry.projectName}</TableCell>
        <TableCell>{entry.engineName}</TableCell>
        <TableCell>{dayjs.utc(entry.createdAt).local().format("lll")}</TableCell>
        <TableCell>{entry.status}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Grid container alignItems={"center"}>
            <Grid item sm={12}>
              <Collapse in={show} mountOnEnter unmountOnExit>
                <Box>
                  {loading && <Typography>Loading...</Typography>}
                  {error && <Typography color="error">{error}</Typography>}
                  {executionHistories && executionHistories.length > 0 ? (
                    executionHistories.map((history, index) => (
                      <React.Fragment key={history.id}>
                        <EntryDetails entry={history} entryDataset={entryDataset} promptId={entryPromptId} />
                        {index < executionHistories.length - 1 && <hr />}
                      </React.Fragment>
                    ))
                  ) : (
                    !loading && !error && <EntryDetailsFailed key={entry.id} entry={entry} />
                  )}
                </Box>
              </Collapse>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Entry;
