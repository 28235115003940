import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import { dayjs } from "../../Services/dateUtil";

const RenderField = ({ label, value }) => (
  <Grid container spacing={2} alignItems="center">
    <Grid item sm={4}><b>{label}</b></Grid>
    <Grid item sm={8}>{value}</Grid>
  </Grid>
);

const parProperties = (string) => {
  try {
    const json = JSON.parse(string);
    return formatText(JSON.stringify(json, null, "\t"));
  } catch (e) {
    return string;
  }
};

const EntryDetails = ({ entry, entryDataset, promptId }) => {
  const { homeDispatch } = useContext(HomeAppContext);
  const [entryData, setEntryData] = useState(entry);
  const [loading, setLoading] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [openDatasets, setOpenDatasets] = useState(false);
  const [openResponse, setOpenResponse] = useState(false);

  const handleOpenPrompt = () => setOpenPrompt(true);
  const handleClosePrompt = () => setOpenPrompt(false);
  
  const handleOpenDatasets = () => setOpenDatasets(true);
  const handleCloseDatasets = () => setOpenDatasets(false);

  const handleOpenResponse = () => setOpenResponse(true);
  const handleCloseResponse = () => setOpenResponse(false);

const RenderFields = ({ entry, handleOpenPrompt, handleOpenDatasets, handleOpenResponse }) => (
  <Box p={2}>
    <RenderField label="Id" value={entry.id} />
    <RenderField label="Prompt Id" value={promptId} />
    <RenderField label="Chat Id" value={entry.azureChatId} />
    <RenderField label="Engine Name" value={entry.engineName} />
    <RenderField label="Temperature" value={entry.temperature}/>
    <RenderField label="IsJson" value={entry.isJson !== undefined ? entry.isJson.toString() : "N/A"}/>
    <RenderField label="Prompt Tokens" value={entry.promptTokens} />
    <RenderField label="Completion Tokens" value={entry.completionTokens} />
    <RenderField label="Total Tokens" value={entry.totalTokens} />
    <RenderField label="Executed At" value={dayjs.utc(entry.executedAt).local().format("lll")} />
    <RenderField label="Finished At" value={dayjs.utc(entry.finishedAt).local().format("lll")} />
    <RenderField label="Is Success" value={entry.isSuccess ? "Success" : "Failed"} />
    <Button variant="outlined" color="primary" onClick={handleOpenPrompt}>
      Click here to see Prompt
    </Button>
    <Button variant="outlined" color="primary" onClick={handleOpenDatasets} style={{ marginLeft: 10 }}>
      Click here to see Datasets
    </Button>
    <Button variant="outlined" color="primary" onClick={handleOpenResponse} style={{ marginLeft: 10 }}>
      Click here to see Response
    </Button>
  </Box>
);

  if (loading) {
    return <CircularProgress />;
  }

  if (!entryData) {
    return <Typography variant="body2">No data available</Typography>;
  }

  return (
    <>
      <Grid container spacing={4} className="Details">
        <Grid item sm={12}>
          <RenderFields entry={entryData} handleOpenPrompt={handleOpenPrompt} handleOpenResponse={handleOpenResponse} handleOpenDatasets={handleOpenDatasets} />
        </Grid>
      </Grid>

      <Dialog open={openPrompt} onClose={handleClosePrompt} fullWidth maxWidth="md">
        <DialogTitle>Prompt</DialogTitle>
        <DialogContent>
          <Typography component="div" style={{ whiteSpace: "pre-wrap" }}>
            {parProperties(entryData.prompt)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePrompt} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openResponse} onClose={handleCloseResponse} fullWidth maxWidth="md">
        <DialogTitle>Response</DialogTitle>
        <DialogContent>
          <Typography component="div" style={{ whiteSpace: "pre-wrap" }}>
            {parProperties(entryData.response)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResponse} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDatasets} onClose={handleCloseDatasets} fullWidth maxWidth="md">
        <DialogTitle>Datasets</DialogTitle>
        <DialogContent>
          <Typography component="div" style={{ whiteSpace: "pre-wrap" }}>
            {parProperties(entryDataset)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDatasets} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EntryDetails;
