import { useState, useEffect, useContext } from "react";
import {
  CircularProgress,
  TableCell,
  TableRow,
  Typography,
  Collapse,
  Box,
  MenuItem, 
  Select, 
  FormControl, 
  InputLabel
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import React from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import {
  GeDeleteIcon,
  GeEnableIcon,
  GeSaveIcon
} from "../General/GeneralIcons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { dayjs } from "../../Services/dateUtil";
import TabularField from "../General/TabularField";
import StateIndicator from "../General/StateIndicator";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import MyDataGrid from "../General/Pagination/MyDataGrid";
import EditLabelDialog from "./EditLabelDialog";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 20
  }
}));

const formatText = (string) =>
  string
    ? string.split("\\n").map((item, key) => {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        );
      })
    : "";
const parProperties = (string) => {
  try {
    const json = JSON.parse(string);
    return formatText(JSON.stringify(json, null, "\t"));
  } catch (e) {
    return string;
  }
};

const maxLength = 100; // Maximum length of the truncated text

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
};

const RenderExecutionEntry = ({ entry }) => {
  console.log(entry); // Check if `isJson` exists
  const [editLabelDialogOpen, setEditLabelDialogOpen] = useState(false);
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const [promptExpanded, setPromptExpanded] = useState(false);
  const [responseExpanded, setResponseExpanded] = useState(false);

  const handleClickEditLabel = () => {
    setEditLabelDialogOpen(true);
  };

  const onLabelAndStatusChange = (entity) => {
    const dto = {
      id: entity.id,
      label: entity.label,
      isActive: entity.isActive
    };

    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Updating the record", loading: true }
    });
    api
      .put(`/api/ai-prompts/history/label`, dto)
      .then((r) => {
        if (r.data === true) {
          homeDispatch({
            type: "NOTIFICATION",
            data: { message: "Updated the record successfuly", type: "success" }
          });
        }
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  return (
    <>
      <TableRow sx={{ "&>td": { borderBottomStyle: "none" } }}>
        <TableCell>
          <Typography variant="body2" display="inline">
            {dayjs(entry.executedAt)
              .utc("z")
              .local()
              .format("YYYY-MM-DD, HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            <div style={{ whiteSpace: "pre-wrap" }}>
              {promptExpanded
                ? parProperties(entry.design)
                : parProperties(truncateText(entry.design, maxLength))}
              {entry.design.length > maxLength && (
                <div
                  onClick={() => setPromptExpanded(!promptExpanded)}
                  style={{
                    cursor: "pointer"
                  }}
                >
                  <StateIndicator
                    type="info"
                    message={promptExpanded ? "Show less" : "Show more"}
                    scale={70}
                  />
                </div>
              )}
            </div>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.temperature}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.isJson !== undefined ? entry.isJson.toString() : "N/A"}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.totalTokens}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.promptVersion}
          </Typography>
        </TableCell>
        <TableCell>
          <Grid container spacing={1}>
            <Grid item sm={9}>
              <Typography variant="body2" display="inline">
                <div style={{ whiteSpace: "pre-wrap" }}>
                  {entry.label !== null ? parProperties(entry.label) : ""}
                </div>
              </Typography>
            </Grid>
            <Grid item sm={1}>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label={
                  entry.isActive ? "active style guide" : "inactive style guide"
                }
                component="span"
                title={entry.isActive ? "active style guide" : "inactive style guide"}
              >
                {entry.isActive ? (
                  <StarIcon fontSize="inherit" />
                ) : (
                  <StarOutlineIcon fontSize="inherit" />
                )}
              </IconButton>
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid item sm={1}>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="edit label"
                onClick={handleClickEditLabel}
                component="span"
                title="Edit label"
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <EditLabelDialog
        open={editLabelDialogOpen}
        setOpen={setEditLabelDialogOpen}
        entry={entry}
        onCloseSuccess={(value) => onLabelAndStatusChange(value)}
      />
    </>
  );
};

const RenderExecutionHistories = ({ entry }) => {
  const [count, setCount] = useState(null);
  const [histories, setHistories] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");

  useEffect(() => {
    console.log("Updated Histories:", histories);
    if (histories.length > 0) {
      const latestVersion = Math.max(...histories.map(history => history.promptVersion));
      setSelectedVersion(latestVersion);
    }
  }, [histories]);

  const tableOptions = {
    columns: [
      {
        name: "Date",
        description: "Date",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "ClientDesign",
        description: "Style Guide Sent",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "Response",
        description: "Response",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "Temperature",
        description: "Temperature",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      },
      {
        name: "IsJson",
        description: "Output as JSON",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      },
      {
        name: "Token",
        description: "Total Tokens",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      },
      {
        name: "Version",
        description: "Version",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      },
      {
        name: "Label",
        description: "Label",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 3
      }
    ],
    defaultSort: [{ name: "Date", order: "-" }],
    additionalFilters: []
  };

  const onPageLoaded = (items, count) => {
    const newHistories = items.flatMap(item => item.promptExecutionHistory);
    
    if (JSON.stringify(histories) !== JSON.stringify(newHistories)) {
      setHistories(newHistories);
      setCount(count);
    }
  };

  const handleVersionChange = (event) => {
    setSelectedVersion(event.target.value);
  };

  const uniqueVersions = [...new Set(histories.map(history => history.promptVersion))];

  return (
    <React.Fragment>
      {entry.aiEngines.map((aiEngine) => (
        <Grid container spacing={2} key={aiEngine.id}>
          <Grid item sm={2}>
            <Typography variant="h6" display="inline">
              {aiEngine.text} Results
            </Typography>
            <br></br><br></br>
            <FormControl variant="outlined" size="small" fullWidth>
              <Select
                labelId="version-select-label"
                value={selectedVersion}
                onChange={handleVersionChange}
                label="Select Version"
              >
                {uniqueVersions.map(version => (
                  <MenuItem key={version} value={version}>
                    Version {version}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <MyDataGrid
            options={tableOptions}
            url={`/api/ai-prompts/${entry.id}/history`}
            label={"execution history"}
            onPageLoaded={onPageLoaded}
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowsPerPage={10}
            renderItem={(item, index) => (
              <React.Fragment key={index}>
                {item.promptExecutionHistory
                  .filter(execution => selectedVersion === "" || execution.promptVersion === selectedVersion)
                  .map((execution, execIndex) =>
                    item.aiEngineName === aiEngine.text ? (
                      <RenderExecutionEntry key={execIndex} entry={execution} />
                    ) : null
                  )}
              </React.Fragment>
            )}
            compactStatuses={true}
          />
        </Grid>
      ))}
    </React.Fragment>
  );
};

const Entry = ({
  entry,
  allowActions = true,
  isAdmin = false,
  onEdit,
  onRemove
}) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [promptExpanded, setPromptExpanded] = useState(false);
  const showActions =
    allowActions && (isAdmin ? true : entry.role.name !== "Admin");

  const handleClickEdit = (event) => {
    onEdit(event, entry);
  };

  const handleClickRemove = (event) => {
    onRemove(event, entry);
  };

  const toggle = () => {
    setShow((show) => !show);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "&>td": { borderBottomStyle: "none" } }}>
        <TableCell>
          <Grid container alignItems="center">
            <Grid item style={{ flex: 1 }}>
              <Typography variant="body2" display="inline">
                {entry.id}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.clientDesignClients.map((e) => e.text).join(", ")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
          {entry.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
          <div style={{ whiteSpace: "pre-wrap" }}>
                        {promptExpanded
                          ? parProperties(entry.design)
                          : parProperties(
                              truncateText(entry.design, maxLength)
                            )}
                        {entry.design.length > maxLength && (
                          <div
                            onClick={() => setPromptExpanded(!promptExpanded)}
                            style={{
                              cursor: "pointer"
                            }}
                          >
                            <StateIndicator
                              type="info"
                              message={
                                promptExpanded ? "Show less" : "Show more"
                              }
                              scale={70}
                            />
                          </div>
                        )}
                      </div>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
          {entry.language ? entry.language.name : ""} 
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
          {entry.createdBy}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {dayjs(entry.createdAt)
              .utc("z")
              .local()
              .format("YYYY-MM-DD, HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          {showActions && (
            <React.Fragment>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="edit style guide"
                component="span"
                onClick={handleClickEdit}
                title="Edit Style Guide"
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="remove style guide"
                component="span"
                onClick={handleClickRemove}
                title="Remove Style Guide"
              >
                <GeDeleteIcon fontSize="inherit" />
              </IconButton>
            </React.Fragment>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Entry;
