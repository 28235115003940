import React, { useContext } from "react";

import AddBoxIcon from "@mui/icons-material/AddBox";
import Button from "@mui/material/Button";
import ConfirmDialog from "../General/ConfirmDialog";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import MyDataGrid from "../General/Pagination/MyDataGrid";
import Edit from "./Edit";
import Entry from "./Entry";
import Create from "./Create";
import dayjs from "dayjs";

const providerValues = [
  { id: "xtrf", text: "XTRF" },
  { id: "tms", text: "TMS" },
];

export const periodLogs = [
  {
    label: "Current month",
    from: dayjs().startOf("month").startOf("day"),
    to: dayjs().endOf("month").endOf("day")
  },
  {
    label: "Last month",
    from: dayjs().subtract(1, "month").startOf("month").startOf("day"),
    to: dayjs().subtract(1, "month").endOf("month").endOf("day")
  }
];
const statusValues = [
  { id: 1, text: "Active" },
  { id: 0, text: "Inactive" },
];

export default function List(props) {
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openRemove, setOpenRemove] = React.useState(false);
  const [removeParams, setRemoveParams] = React.useState(false);
  const [currentEntry, setCurrentEntry] = React.useState(null);
  const [refresh, setRefresh] = React.useState(0);

  const isUser =
    homeState.userProfile.role === "Admin" ||
    homeState.userProfile.role === "PowerUser" ||
    homeState.userProfile.role === "User";

  const allowActions = isUser;

  const removeClientDescription = (params) => {
    console.log(params);
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Removing client description", loading: true }
    });
    api
      .delete(`/api/client-descriptions/${params.id}`)
      .then((response) => {
        if (response.data) {
          homeDispatch({
            type: "NOTIFICATION",
            data: { message: "Client Description deleted successfuly", type: "success" }
          });
        }
        refreshItems();
      })
      .catch((error) => {
        errorManagement.formErrors(error, homeDispatch);
      });
  };

  const tableOptions = {
    columns: [
      {
        name: "Id",
        description: "Id",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "ClientId",
        description: "Client",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "Name",
        description: "Name",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 3
      },
      {
        name: "Description",
        description: "Description",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 3
      },
      {
        name: "CreatedBy",
        description: "Created By",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 3
      },
      {
        name: "CreatedAt",
        description: "Created At",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 1
      },
      {
        name: "Actions",
        description: "",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      }
    ],
    defaultSort: [{ name: "CreatedAt", order: "-" }],
    additionalFilters: [
      {
        name: "Name",
        description: "Name",
        filter: {
          enabled: true,
          name: "Name",
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Filter name"
        },
        size: allowActions ? 3 : "auto"
      },
      {
        name: "Description",
        description: "Description",
        filter: {
          enabled: true,
          name: "Description",
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Filter description"
        },
        size: allowActions ? 3 : "auto"
      },
      {
        name: "UserId",
        description: "User",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/users/dropdown",
            multiple: false,
            liveOptions: false,
            checkbox: false
          }
        },
        size: 2
      },
      {
        name: "CreatedAt",
        description: "Created At",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "dateRange",
          defaultValue: 2,
          value: periodLogs,
          options: {
            addNullPeriod: true
          }
        },
        size: 4
      },
      {
        name: "Provider",
        description: "Client Provider",
        filter: {
          enabled: true,
          comparison: "==",
          type: "select",
          value: providerValues,
        },
        size: 2
      },
      {
        name: "XtrfClientId",
        description: "XTRF Clients",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/clients/xtrfclients",
            multiple: true,
            liveOptions: false,
            checkbox: false
          }
        },
        size: 4
      },
      {
        name: "TmsClientId",
        description: "TMS Clients",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/clients/tmsclients",
            multiple: true,
            liveOptions: false,
            checkbox: false
          }
        },
        size: 4
      },
      //   {
      //     name: "Tags",
      //     description: "Tags",
      //     filter: {
      //       enabled: true,
      //       name: "NameLike",
      //       comparison: "@=*",
      //       type: "text",
      //       value: "",
      //       defaultIndex: 0,
      //       placeholder: "Filter prompt tag"
      //     },
      //     size: allowActions ? 2 : "auto"
      //   },
    ]
  };

  React.useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "Client Descriptions"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  const handleClickCreate = (event) => {
    setOpenCreate(true);
  };

  const handleClickEdit = (event, entry) => {
    setCurrentEntry(entry);
    setOpenEdit(true);
  };

  const handleClickRemove = (event, entry) => {
    setCurrentEntry(entry);
    setRemoveParams({ id: entry.id });
    setOpenRemove(true);
  };

  const cancelRemove = () => {
    setRemoveParams(null);
  };

  const refreshItems = () => {
    setRefresh(refresh + 1);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"space-between"}>
        {allowActions && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickCreate}
              startIcon={<AddBoxIcon />}
            >
              New Client Description
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <MyDataGrid
            options={tableOptions}
            url={"/api/client-descriptions"}
            refresh={refresh}
            label={"Client Descriptions"}
            renderItem={(item, index) => (
              <Entry
                key={index}
                entry={item}
                allowActions={allowActions}
                isAdmin={isUser}
                onEdit={handleClickEdit}
                onRemove={handleClickRemove}
              />
            )}
          />
        </Grid>
      </Grid>
      {allowActions && (
        <React.Fragment>
          <Edit
            open={openEdit}
            setOpen={setOpenEdit}
            entry={currentEntry}
            onEdit={refreshItems}
          />
          <Create
            open={openCreate}
            setOpen={setOpenCreate}
            onCreate={refreshItems}
          />
          {currentEntry && (
            <ConfirmDialog
              open={openRemove}
              setOpen={setOpenRemove}
              title={"Remove " + currentEntry.name + "?"}
              message={
                'Are you sure that you want to remove the Client Description "' +
                currentEntry.name +
                '"' +
                "?"
              }
              onCloseSuccess={removeClientDescription}
              successParams={removeParams}
              onClose={cancelRemove}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
