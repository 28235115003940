import { useState, useEffect, useContext, useRef } from "react";
import ShareDialog from './ShareDialog';
import {
  CircularProgress,
  TableCell,
  TableRow,
  Typography,
  Collapse,
  Box,
  MenuItem, 
  Select, 
  FormControl, 
  InputLabel,
  Tooltip, 
  Fab
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import React from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import {
  GeDeleteIcon,
  GeEnableIcon,
  GeSaveIcon
} from "../General/GeneralIcons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { dayjs } from "../../Services/dateUtil";
import TabularField from "../General/TabularField";
import StateIndicator from "../General/StateIndicator";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import MyDataGrid from "../General/Pagination/MyDataGrid";
import EditLabelDialog from "./EditLabelDialog";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ShareIcon from "@mui/icons-material/Share";
import GetAppIcon from '@mui/icons-material/GetApp';
import VisibilityIcon from '@mui/icons-material/Visibility';

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 20
  }
}));

const formatText = (string) =>
  string
    ? string.split("\\n").map((item, key) => {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        );
      })
    : "";
const parProperties = (string) => {
  try {
    const json = JSON.parse(string);
    return formatText(JSON.stringify(json, null, "\t"));
  } catch (e) {
    return string;
  }
};

const maxLength = 100; // Maximum length of the truncated text

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
};

const RenderExecutionEntry = ({ entry }) => {
  console.log(entry); // Check if `isJson` exists
  const [editLabelDialogOpen, setEditLabelDialogOpen] = useState(false);
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const [promptExpanded, setPromptExpanded] = useState(false);
  const [responseExpanded, setResponseExpanded] = useState(false);

  const handleClickEditLabel = () => {
    setEditLabelDialogOpen(true);
  };

  const onLabelAndStatusChange = (entity) => {
    const dto = {
      id: entity.id,
      label: entity.label,
      isActive: entity.isActive
    };

    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Updating the record", loading: true }
    });
    api
      .put(`/api/ai-prompts/history/label`, dto)
      .then((r) => {
        if (r.data === true) {
          homeDispatch({
            type: "NOTIFICATION",
            data: { message: "Updated the record successfuly", type: "success" }
          });
        }
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  return (
    <>
      <TableRow sx={{ "&>td": { borderBottomStyle: "none" } }}>
        <TableCell>
          <Typography variant="body2" display="inline">
            {dayjs(entry.executedAt)
              .utc("z")
              .local()
              .format("YYYY-MM-DD, HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            <div style={{ whiteSpace: "pre-wrap" }}>
              {promptExpanded
                ? parProperties(entry.prompt)
                : parProperties(truncateText(entry.prompt, maxLength))}
              {entry.prompt.length > maxLength && (
                <div
                  onClick={() => setPromptExpanded(!promptExpanded)}
                  style={{
                    cursor: "pointer"
                  }}
                >
                  <StateIndicator
                    type="info"
                    message={promptExpanded ? "Show less" : "Show more"}
                    scale={70}
                  />
                </div>
              )}
            </div>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            <div style={{ whiteSpace: "pre-wrap" }}>
              {responseExpanded
                ? parProperties(entry.response)
                : parProperties(truncateText(entry.response, maxLength))}
              {entry.response.length > maxLength && (
                <div
                  onClick={() => setResponseExpanded(!responseExpanded)}
                  style={{
                    cursor: "pointer"
                  }}
                >
                  <StateIndicator
                    type="info"
                    message={responseExpanded ? "Show less" : "Show more"}
                    scale={70}
                  />
                </div>
              )}
            </div>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.temperature}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.isJson !== undefined ? entry.isJson.toString() : "N/A"}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.totalTokens}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.promptVersion}
          </Typography>
        </TableCell>
        <TableCell>
          <Grid container spacing={1}>
            <Grid item sm={9}>
              <Typography variant="body2" display="inline">
                <div style={{ whiteSpace: "pre-wrap" }}>
                  {entry.label !== null ? parProperties(entry.label) : ""}
                </div>
              </Typography>
            </Grid>
            <Grid item sm={1}>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label={
                  entry.isActive ? "active prompt" : "inactive prompt"
                }
                component="span"
                title={entry.isActive ? "active prompt" : "inactive prompt"}
              >
                {entry.isActive ? (
                  <StarIcon fontSize="inherit" />
                ) : (
                  <StarOutlineIcon fontSize="inherit" />
                )}
              </IconButton>
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid item sm={1}>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="edit label"
                onClick={handleClickEditLabel}
                component="span"
                title="Edit label"
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <EditLabelDialog
        open={editLabelDialogOpen}
        setOpen={setEditLabelDialogOpen}
        entry={entry}
        onCloseSuccess={(value) => onLabelAndStatusChange(value)}
      />
    </>
  );
};

const RenderExecutionHistories = ({ entry }) => {
  const [count, setCount] = useState(null);
  const [histories, setHistories] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    const fetchVersions = async () => {
      try {
        const response = await api.get(`/api/ai-prompts/${entry.id}/versions`);
        if (response.data) {
          const versionsData = response.data;
          setVersions(versionsData);
          if (versionsData.length > 0) {
            setSelectedVersion(versionsData[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching versions', error);
      }
    };

    fetchVersions();
  }, [entry.id]);

  const tableOptions = {
    columns: [
      {
        name: "Date",
        description: "Date",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "Prompt",
        description: "Prompt Sent",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "Response",
        description: "Response",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "Temperature",
        description: "Temperature",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      },
      {
        name: "IsJson",
        description: "Output as JSON",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      },
      {
        name: "Token",
        description: "Total Tokens",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      },
      {
        name: "Version",
        description: "Version",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      },
      {
        name: "Label",
        description: "Label",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 3
      }
    ],
    defaultSort: [{ name: "Date", order: "-" }],
    additionalFilters: []
  };

  const onPageLoaded = (items, count) => {
    const newHistories = items.flatMap(item => item.promptExecutionHistory);
    
    if (JSON.stringify(histories) !== JSON.stringify(newHistories)) {
      setHistories(newHistories);
      setCount(count);
    }
  };

  const handleVersionChange = (event) => {
    setSelectedVersion(event.target.value);
  };

  const uniqueVersions = [...new Set(histories.map(history => history.promptVersion))];

  return (
    <React.Fragment>
      {entry.aiEngines.map((aiEngine) => (
        <Grid container spacing={2} key={aiEngine.id}>
          <Grid item sm={2}>
            <Typography variant="h6" display="inline">
              {aiEngine.text} Results
            </Typography>
            <br></br><br></br>
            <FormControl variant="outlined" size="small" fullWidth>
              <Select
                labelId="version-select-label"
                value={selectedVersion}
                onChange={handleVersionChange}
                label="Select Version"
              >
                {versions.map(version => (
                  <MenuItem key={version} value={version}>
                    Version {version}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <MyDataGrid
            options={tableOptions}
            url={`/api/ai-prompts/${entry.id}/history?version=${selectedVersion}`}
            label={"execution history"}
            onPageLoaded={onPageLoaded}
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowsPerPage={10}
            renderItem={(item, index) => (
              <React.Fragment key={index}>
                {item.promptExecutionHistory.map((execution, execIndex) =>
                  item.aiEngineName === aiEngine.text ? (
                    <RenderExecutionEntry key={execIndex} entry={execution} />
                  ) : null
                )}
              </React.Fragment>
            )}
            compactStatuses={true}
          />
        </Grid>
      ))}
    </React.Fragment>
  );
};

const Entry = ({
  entry,
  allowActions = true,
  isAdmin = false,
  onEdit,
  onRemove,
  onClone,
  onExecute,
  onExport
}) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [promptExpanded, setPromptExpanded] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [sharingOption, setSharingOption] = useState('private');
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const showActions =
    allowActions && (isAdmin ? true : entry.role.name !== "Admin");

  const getSharingOption = (shareType) => {
      switch (shareType) {
        case 0: return 'private';
        case 1: return 'public-read-only';
        case 2: return 'public';
      }
  };

  const handleShareClick = () => {
    console.log(entry.createdBy.id, homeState.userProfile.userId);
    setSharingOption(getSharingOption(entry.shareType));
    setShareDialogOpen(true);
  };
  
  const handleShareSave = async (newOption) => {
    setSharingOption(newOption);
  
    try {
      const sharingOptionDto = {
        sharingId: entry.id,
        sharingOption: newOption,
      };

      const response = await api.put(`/api/ai-prompts/sharing`, sharingOptionDto);
  
      if (response.status === 200) {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Sharing option updated successfully", type: "success" }
        });
      } else {
        throw new Error("Failed to update sharing option");
      }
      console.log('newOption', newOption);
      entry.shareType = ['private', 'public-read-only', 'public'].indexOf(newOption);
    } catch (error) {
      console.error("Error updating sharing option:", error);
      homeDispatch({
        type: "NOTIFICATION",
        data: { message: "Error updating sharing option", type: "error" }
      });
    }
  };  

  const handleClickEdit = (event) => {
    onEdit(event, entry);
    setShowCommands(false);
  };

  const handleClickRemove = (event) => {
    onRemove(event, entry);
    setShowCommands(false);
  };

  const handleClickClone = (event) => {
    onClone(event, entry);
    setShowCommands(false);
  };

  const handleClickExecute = (event) => {
    onExecute(event, entry);
    setShowCommands(false);
  };

  const handleClickExport = (event) => {
    onExport(event, entry);
    setShowCommands(false);
  };

  const toggle = () => {
    setShow((show) => !show);
  };

  const [showCommands, setShowCommands] = useState(false);
  const commandBoxRef = useRef(null);

  const toggleCommands = () => setShowCommands(!showCommands);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (commandBoxRef.current && !commandBoxRef.current.contains(event.target)) {
        setShowCommands(false);
      }
    };

    if (showCommands) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showCommands]);

  return (
    <React.Fragment>
      <TableRow sx={{ "&>td": { borderBottomStyle: "none" } }}>
        <TableCell>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton onClick={toggle} size="small">
                {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Typography variant="body2" display="inline">
                {entry.id}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.promptName}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.aiPromptClients.map((e) => e.text).join(", ")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.aiEngines.map((engine) => engine.text).join(", ")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.createdBy ? entry.createdBy.fullName : ""}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.modifiedUser ? entry.modifiedUser.fullName : ""}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {dayjs(entry.modifiedAt)
              .utc("z")
              .local()
              .format("YYYY-MM-DD, HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.tags?.join(", ")}
          </Typography>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
        <Box position="relative" display="inline-block" ref={commandBoxRef}>
            <IconButton onClick={toggleCommands} color="primary">
              <SettingsIcon />
            </IconButton>

            {showCommands && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: '100%',
                  transformOrigin: "top right",
                  transform: "translateY(-50%)",
                  mr: 1,
                  p: 1,
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  bgcolor: "background.paper",
                  boxShadow: 3,
                  borderRadius: "12px",
                  zIndex: 10,
                  animation: showCommands ? 'openBoxEffect 0.5s ease forwards' : 'none',
                  "@keyframes openBoxEffect": {
                    "0%": {
                      opacity: 0,
                      transform: "scale(0.3) translateY(-50%)",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)"
                    },
                    "60%": {
                      opacity: 1,
                      transform: "scale(1.1) translateY(-50%)",
                      boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.2)"
                    },
                    "100%": {
                      opacity: 1,
                      transform: "scale(1) translateY(-50%)",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
                    }
                  }
                }}
              >
              <Tooltip title="Execute" placement="top">
                  <Fab size="small" color="primary" onClick={handleClickExecute}>
                    <PlayArrowIcon />
                  </Fab>
                </Tooltip>
                {((entry.createdBy.id === homeState.userProfile.userId) || (entry.shareType === 2)) && (
                  <Tooltip title="Edit" placement="top">
                    <Fab size="small" color="primary" onClick={handleClickEdit}>
                      <EditIcon />
                    </Fab>
                  </Tooltip>
                )}
                {((entry.createdBy.id !== homeState.userProfile.userId) && (entry.shareType === 1)) && (
                  <Tooltip title="View Details" placement="top">
                    <Fab size="small" color="primary" onClick={handleClickEdit}>
                      <VisibilityIcon />
                    </Fab>
                  </Tooltip>
                )}
                <Tooltip title="Clone" placement="top">
                  <Fab size="small" color="primary" onClick={handleClickClone}>
                    <FileCopyIcon />
                  </Fab>
                </Tooltip>
                {entry.createdBy.id === homeState.userProfile.userId && (
                  <Tooltip title="Delete" placement="top">
                    <Fab size="small" color="primary" onClick={handleClickRemove}>
                      <DeleteIcon />
                    </Fab>
                  </Tooltip>
                )}
                <Tooltip title="Export to CSV" placement="top">
                  <Fab size="small" color="primary" onClick={handleClickExport}>
                    <GetAppIcon />
                  </Fab>
                </Tooltip>
                {entry.createdBy.id === homeState.userProfile.userId && (                
                  <Tooltip title="Share" placement="top">
                  <Fab size="small" color="primary" onClick={handleShareClick}>
                    <ShareIcon />
                  </Fab>
                </Tooltip>
              )}
              </Box>
            )}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow sx={{ "&>td": { borderBottomStyle: "none" } }}>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={show} mountOnEnter unmountOnExit>
            <Box className={classes.detailsContainer}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <TabularField
                    label={"Prompt Id"}
                    labelGridSize={3}
                    value={
                      <div style={{ whiteSpace: "pre-wrap" }}>{entry.id}</div>
                    }
                  />
                </Grid>
                <Grid item sm={6}></Grid>
                <Grid item sm={6}>
                  <TabularField
                    label={"Temperature"}
                    labelGridSize={3}
                    value={
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {parProperties(entry.temperature)}
                      </div>
                    }
                  />
                </Grid>
                <Grid item sm={6}></Grid>
                <Grid item sm={6}>
                  <TabularField
                    label={"Output as JSON"}
                    labelGridSize={3}
                    value={
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {parProperties(entry.isJson)}
                      </div>
                    }
                  />
                </Grid>
                <Grid item sm={6}></Grid>
                <Grid item sm={6}>
                  <TabularField
                    label={"Prompt"}
                    labelGridSize={3}
                    value={
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {promptExpanded
                          ? parProperties(entry.prompt)
                          : parProperties(
                              truncateText(entry.prompt, maxLength)
                            )}
                        {entry.prompt.length > maxLength && (
                          <div
                            onClick={() => setPromptExpanded(!promptExpanded)}
                            style={{
                              cursor: "pointer"
                            }}
                          >
                            <StateIndicator
                              type="info"
                              message={
                                promptExpanded ? "Show less" : "Show more"
                              }
                              scale={70}
                            />
                          </div>
                        )}
                      </div>
                    }
                  />
                </Grid>
                <Grid item sm={6}></Grid>
                <Grid item sm={6}>
                  <TabularField
                    label={"Prompt Description"}
                    labelGridSize={3}
                    value={
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {parProperties(entry.description)}
                      </div>
                    }
                  />
                </Grid>
                <Grid item sm={6}></Grid>
                {entry.dataset.id !== 0 ? (
                  <Grid item sm={6}>
                    <TabularField
                      label={"Dataset"}
                      labelGridSize={3}
                      value={
                        <div style={{ whiteSpace: "pre-wrap" }}>
                          {parProperties(entry.dataset.text)}
                        </div>
                      }
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {show && (
        <TableRow>
          <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={show} mountOnEnter unmountOnExit>
              <Box className={classes.detailsContainer}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <RenderExecutionHistories key={entry.id} entry={entry} />
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      <ShareDialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        onSave={handleShareSave}
        currentSharingOption={sharingOption}
      />
    </React.Fragment>
  );
};

export default Entry;
