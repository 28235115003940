import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useContext } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Slider from "@mui/material/Slider";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import _ from "lodash";

export const AIEngineFormMode = {
  NEW: "new",
  EDIT: "edit"
};

export default function Form({
  mode = AIEngineFormMode.NEW,
  entity = null,
  errors = [],
  setEntity,
  engineFamilies = [],
  engineGroups = [],
  loading,
  submitted,
  setSaveDisabled
}) {
  const onInputChange = (event, property) =>
    setEntity({ ...entity, [property]: event.target.value });

  const onTemperatureChange = (event, newValue) => {
    setEntity({ ...entity, temperature: newValue });
  };

  const onEngineFamilyChange = (value, property) => {
    setEntity({ ...entity, [property]: value });
  };

  const onEngineGroupChange = (value, property) => {
    setEntity({ ...entity, [property]: value });
  };

  const onCostChange = (value, property) =>
    setEntity({ ...entity, [property]: value });

  const onMaxTokensChange = (value, property) =>
    setEntity({ ...entity, [property]: value });

  const onMaxCallsChange = (value, property) =>
    setEntity({ ...entity, [property]: value });

  const onCheckChange = (event, property) =>
    setEntity({ ...entity, [property]: event.target.checked });

  return entity === null ? null : (
    <Grid container spacing={2}>
      {/* Left side for settings */}
      <Grid item sm={6}>
      <TextField
              sx={{ marginBottom: 3 }}
              required
              label="New AI Engine Name"
              fullWidth
              value={entity.engineName}
              onChange={(event) => onInputChange(event, "engineName")}
            />
          <TextField
            sx={{ marginBottom: 3 }}
              label="AI Engine Description"
              fullWidth
              value={entity.description}
              multiline
              rows={4}
              onChange={(event) => onInputChange(event, "description")}
            />

<FormControlLabel
            control={
              <Checkbox
                checked={entity.isAvailableToTeam}
                onChange={(event) => onCheckChange(event, "isAvailableToTeam")}
                color="primary"
                name="changePassword"
              />
            }
            label="Make Available To Team"
            sx={{ marginBottom: 3 }}
          />

<FormControlLabel
  control={
    <Checkbox
      checked={entity.isAssistant}
      onChange={(event) => onCheckChange(event, "isAssistant")}
      color="primary"
    />
  }
  label="Make Available To Assistant"
/>
<Typography variant="body2" color="textSecondary" sx={{ marginBottom: 3 }}>
*Ensure this engine is available in Azure OpenAI Studio.
</Typography>



 

<FormControlLabel
          control={
            <Checkbox
              checked={entity.isActive}
              onChange={(event) => onCheckChange(event, "isActive")}
              color="primary"
            />
          }
          label="Set as Active"
          sx={{ marginBottom: 3 }}
        />

<br></br>

       <FormControlLabel
  control={
    <Checkbox
      checked={entity.isDefault}
      onChange={(event) => onCheckChange(event, "isDefault")}
      color="primary"
    />
  }
  label="Set as Default"
/>
<Typography variant="body2" color="textSecondary" sx={{ marginBottom: 3 }}>
  *Setting this engine as default will replace any existing default AI engine for this engine family.
</Typography>
      </Grid>

      {/* Right side for engine details */}
      <Grid item sm={6}>
            <Autocomplete
              sx={{ marginBottom: 3 }}
              freeSolo={true}
              multiple={false}
              disableClearable
              value={entity.engineFamily}
              // onChange={(event, value) =>
              //   onEngineFamilyChange(value, "engineFamily")
              // }
              onInputChange={(event, newInputValue) =>
                onEngineFamilyChange(newInputValue, "engineFamily")
              }
              options={engineFamilies.map((family) => family.text)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Engine Family"
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
            <Autocomplete
              sx={{ marginBottom: 3 }}
              freeSolo={true}
              multiple={false}
              disableClearable
              value={entity.engineGroup}
              // onChange={(event, value) =>
              //   onEngineFamilyChange(value, "engineFamily")
              // }
              onInputChange={(event, newInputValue) =>
                onEngineGroupChange(newInputValue, "engineGroup")
              }
              options={engineGroups.map((group) => group.text)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Engine Group"
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />

            <TextField
            sx={{ marginBottom: 3 }}
              required
              label="API Url"
              fullWidth
              value={entity.apiUrl}
              onChange={(event) => onInputChange(event, "apiUrl")}
            />
            <TextField
            sx={{ marginBottom: 3 }}
              required
              label="API Token"
              fullWidth
              value={entity.apiToken}
              onChange={(event) => onInputChange(event, "apiToken")}
            />
            <TextField
            sx={{ marginBottom: 3 }}
              required
              type="number"
              label="API Cost ($ per 1000 tokens)"
              fullWidth
              inputProps={{
                step: "0.0001"
              }}
              value={entity.apiCost}
              onChange={(event) =>
                onCostChange(parseFloat(event.target.value), "apiCost")
              }
            />
                        <TextField
            sx={{ marginBottom: 3 }}
              required
              type="number"
              label="Max Tokens per Minute"
              fullWidth
              value={entity.maxTokens}
              onChange={(event) =>
                onMaxTokensChange(event.target.value, "maxTokens")
              }
            />
            <TextField
            sx={{ marginBottom: 3 }}
              required
              type="number"
              label="Max Calls per Minute"
              fullWidth
              value={entity.maxCalls}
              onChange={(event) =>
                onMaxCallsChange(event.target.value, "maxCalls")
              }
            />
            <InputLabel>Temperature: {entity.temperature}</InputLabel>

            <Slider
            sx={{ marginBottom: 3 }}
              value={entity.temperature}
              min={0.0}
              step={0.1}
              max={1}
              onChange={onTemperatureChange}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
            />
      </Grid>
    </Grid>
  );
}
