import { CircularProgress, Container } from "@mui/material";

import Grid from "@mui/material/Grid";
import React from "react";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { userStore } from "../Services/store";

export default function RedirectPage({ userProfile }) {
  const history = useHistory();
  React.useEffect(() => {
    if (userStore.profileLoaded()) {
      switch (userStore.user_profile.role) {
        case "Admin":
          history.push("/ai-prompts" + history.location.search);
          break;
        case "PowerUser":
          history.push("/ai-prompts" + history.location.search);
          break;
        case "User":
          history.push("/ai-prompts" + history.location.search);
          break;
        default:
          history.push("/login");
          break;
      }
    } else {
      userStore.clearProfile();
      history.push("/login");
    }
  });

  return (
    <Container>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"center"}
        spacing={4}
      >
        <Grid item>
          <CircularProgress size={24} />
        </Grid>
        <Grid item>
          <Typography>Loading...</Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
