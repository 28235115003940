import React, { useEffect, useState, useContext } from 'react';
import * as signalR from '@microsoft/signalr';
import api from "../../Services/api";
import { Line, LineChart, BarChart, Bar, PieChart, Pie, Tooltip, CartesianGrid, XAxis, YAxis, Legend, ResponsiveContainer, Cell } from 'recharts';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { HomeAppContext } from "../Home";

const PageStats = () => {
    const theme = useTheme();
    const [overallStats, setOverallStats] = useState({});
    const [userStats, setUserStats] = useState([]);
    const [connection, setConnection] = useState(null);
    const [userPageStats, setUserPageStats] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'userName', direction: 'asc' });
    const [sortedPageIndex, setSortedPageIndex] = useState(null);
    const { homeState, homeDispatch } = useContext(HomeAppContext);

    React.useEffect(() => {
        api.get("/api/login/authorized")
            .then(() => {
                homeDispatch({
                    type: "UPDATE_CURRENT_LOCATION",
                    data: "Page Stats",
                });
            })
            .catch((error) => console.error(error));
    }, [homeDispatch]);

    useEffect(() => {
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_BACKEND_URL + "/statshub")
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(() => {
                    console.log('Connected to SignalR Hub');
                    connection.on('ReceiveStatsUpdate', (data) => {
                        console.log("Received Data from SignalR:");
                        console.log("Received Data from SignalR:", data);
                        console.log("Most Active Users Data:", data.overallStats.mostActiveUsers);
                        setOverallStats(data.overallStats);
                        setUserStats(data.userStats);
                    });
                })
                .catch(err => console.error('SignalR Connection Error: ', err));
        }
    }, [connection]);

    useEffect(() => {
        api.get('/api/user-activity/get-overall-stats')
            .then(response => setOverallStats(response.data))
            .catch(error => console.error('Error fetching overall stats', error));

        api.get('/api/user-activity/get-user-stats')
            .then(response => setUserStats(response.data))
            .catch(error => console.error('Error fetching user stats', error));

        api.get('/api/user-activity/get-all-user-page-stats')
            .then(response => setUserPageStats(response.data))
            .catch(error => console.error('Error fetching user page stats', error));
    }, []);

    const handleSort = (key, pageIndex = null) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setSortedPageIndex(pageIndex);
    };

    const sortedUserPageStats = [...userPageStats].sort((a, b) => {
        const { key, direction } = sortConfig;
        if (sortedPageIndex !== null) {
            const aPageViews = a.pageStats[sortedPageIndex]?.views || 0;
            const bPageViews = b.pageStats[sortedPageIndex]?.views || 0;
            if (aPageViews < bPageViews) return direction === 'asc' ? -1 : 1;
            if (aPageViews > bPageViews) return direction === 'asc' ? 1 : -1;
        } else {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const tableStyles = {
        wrapper: {
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center',
        },
        tableContainer: {
            maxHeight: '400px',
            maxWidth: '1111px', 
            overflowX: 'auto',
            overflowY: 'auto',
            margin: '0 auto',  
            marginLeft: '4rem',
            marginBottom: '4rem',
        },
        stickyColumn: {
            position: 'sticky',
            left: 0,
            background: theme.palette.background.paper,
            zIndex: 2,
            borderRight: `2px solid ${theme.palette.divider}`,
        },
        stickyHeader: {
            position: 'sticky',
            top: 0,
            background: theme.palette.error.main,
            color: theme.palette.primary.contrastText,
            zIndex: 3,
            borderBottom: `2px solid ${theme.palette.divider}`,
        },
        borderedCell: {
            borderLeft: `1px solid ${theme.palette.divider}`,
            textAlign: 'center',
            lineHeight: '1.5',
        },
        pageNameCell: {
            borderBottom: `3px solid ${theme.palette.divider}`,
        },
    };

    const pieColors = [theme.palette.primary.main, theme.palette.secondary.main, theme.palette.info.main, theme.palette.error.main];

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <h2>Most Used Pages</h2>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={overallStats.mostUsedPages}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                    dataKey="pageName" 
                    tickFormatter={(name) => name.replace(' ', '\n')}
                    tick={{ fontSize: 12 }} 
                />                               
                <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="views" fill={theme.palette.primary.main} />
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>

                <Grid item xs={12} md={6}>
                    <h2>Least Used Pages</h2>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={overallStats.leastUsedPages}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                    dataKey="pageName" 
                    tickFormatter={(name) => name.replace(' ', '\n')}
                    tick={{ fontSize: 12 }} 
                />                             
                <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="views" stroke={theme.palette.primary.main} />
                        </LineChart>
                    </ResponsiveContainer>
                </Grid>

                <Grid item xs={12} md={12}>
        <h2>Most Active Users</h2>
        <ResponsiveContainer width="100%" height={300}>
            <BarChart data={overallStats.mostActiveUsers}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                    dataKey="userName" 
                    tickFormatter={(name) => name.replace(' ', '\n')} 
                    tick={{ fontSize: 12 }} 
                />                
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="actions" fill={theme.palette.info.main} />
            </BarChart>
        </ResponsiveContainer>
    </Grid>
            </Grid>

            <div>
            <h2 style={{ marginLeft: '4rem', marginTop: '4rem' }}>AIPM Detailed Stats</h2>
            <div style={tableStyles.wrapper}>
                <TableContainer component={Paper} style={tableStyles.tableContainer}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ ...tableStyles.stickyColumn, ...tableStyles.stickyHeader }}>
                                    <TableSortLabel
                                        active={sortConfig.key === 'userName'}
                                        direction={sortConfig.direction}
                                        onClick={() => handleSort('userName')}
                                    >
                                        Users & Pages
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ ...tableStyles.pageNameCell, ...tableStyles.borderedCell }}>
                                <TableSortLabel
                                        active={sortConfig.key === 'totalActions'}
                                        direction={sortConfig.direction}
                                        onClick={() => handleSort('totalActions')}
                                    >
                                    Total Actions
                                    </TableSortLabel>
                                </TableCell>
                                {userPageStats.length > 0 && userPageStats[0].pageStats.map((page, index) => (
                                    <TableCell key={index} style={{ ...tableStyles.pageNameCell, ...tableStyles.borderedCell }}>
                                        <TableSortLabel
                                            active={sortedPageIndex === index}
                                            direction={sortConfig.direction}
                                            onClick={() => handleSort(null, index)}
                                        >
                                            {page.pageName}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedUserPageStats.map((user, index) => (
                                <TableRow key={index}>
                                    <TableCell style={tableStyles.stickyColumn}>{user.userName}</TableCell>
                                    <TableCell style={tableStyles.borderedCell}>{user.totalActions}</TableCell> 
                                    {user.pageStats.map((page, pageIndex) => (
                                        <TableCell key={pageIndex} style={tableStyles.borderedCell}>{page.views}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
        </div>
    );
};

export default PageStats;
