import {
  Checkbox,
  FormControlLabel,
  TextField,
  LinearProgress
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import CustomAutocomplete from "../General/CustomAutocomplete";
import FileUploader from "../General/FileUploader";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";

export const AIAssistantFormMode = {
  NEW: "new",
  EDIT: "edit"
};

export default function Form({
  mode = AIAssistantFormMode.NEW,
  entity = null,
  errors = [],
  setEntity,
  loading,
  submitted,
  setSaveDisabled,
  readOnly = false
}) {
  const [fileCountRemaining, setFileCountRemaining] = useState(20);
  const onInputChange = (event, property) =>
    setEntity({ ...entity, [property]: event.target.value });

  const onDeploymentChange = (value, property) =>
    setEntity({ ...entity, [property]: value });

  const onCheckChange = (event, property) =>
    setEntity({ ...entity, [property]: event.target.checked });

  const onFileUploaded = (value) => {
    const uploadedFiles = value.map((item) => ({
      fileId: item.id,
      name: item.name
    }));

    setEntity({
      ...entity,
      files: uploadedFiles,
      codeInterpreter: uploadedFiles.length > 0 ? true : false
    });

    setFileCountRemaining(
      20 -
        uploadedFiles.length -
        (entity.assistantFiles ? entity.assistantFiles.length : 0)
    );
  };

  const handleExistingFileDelete = (assistantFileId) => {
    console.log(assistantFileId);
    setEntity({
      ...entity,
      assistantFiles: entity.assistantFiles.filter(
        (file) => file.id !== assistantFileId
      )
    });

    setFileCountRemaining(
      20 - entity.files.length - entity.assistantFiles.length
    );
  };

  return entity === null ? null : (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item sm={12}>
          <Grid item sm={12}>
            <TextField
            disabled={readOnly}
              required
              label="New AI Assistant Name"
              fullWidth
              value={entity.name}
              onChange={(event) => onInputChange(event, "name")}
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={12}>
            <TextField
            disabled={readOnly}
              label="Instructions"
              fullWidth
              value={entity.instructions}
              multiline
              rows={4}
              onChange={(event) => onInputChange(event, "instructions")}
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={12}>
            <TextField
            disabled={readOnly}
              label="Description"
              fullWidth
              value={entity.description}
              multiline
              rows={4}
              onChange={(event) => onInputChange(event, "description")}
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={6}>
            <CustomAutocomplete
            disabled={readOnly}
              value={entity.deployment || ""}
              setValue={(value) => onDeploymentChange(value, "deployment")}
              related={null}
              onLoading={setSaveDisabled}
              url={"/api/ai-assistants/deployments/dropdown"}
              label={"Deployment"}
              placeholder={"Select a deployment"}
              noOptionsText={"No deployment found"}
              loadingText={"Searching"}
              margin={"normal"}
              multiple={false}
              liveOptions={true}
              error={_.isArray(errors.deployments)}
              helperText={
                _.isArray(errors.deployments) && errors.deployments.join()
              }
              disableClearable={true}
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={12}>
            <FileUploader
            disabled={readOnly}
              onFileUploaded={onFileUploaded}
              uploadUrl="/api/files"
              acceptedFileExtensions=".c, .cs, .cpp, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .js, .sh, .ts"
              acceptedFileExtensionsMessage={
                ".c, .cs, .cpp, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .js, .sh, .ts"
              }
              maxSize={209720000}
            ></FileUploader>
          </Grid>
        </Grid>
        {mode === AIAssistantFormMode.EDIT && (
          <>
            <Grid item sm={12}>
              <Grid item sm={12}>
                <InputLabel>Existing files in Assistant</InputLabel>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              {loading ? (
                <LinearProgress />
              ) : entity.assistantFiles && entity.assistantFiles.length > 0 ? (
                <>
                  <Grid container spacing={2}>
                    {entity.assistantFiles?.map((f) => (
                      <Grid item key={f.id}>
                        <Chip
                        disabled={readOnly}
                          label={f.filename}
                          onDelete={() =>
                            handleExistingFileDelete(f.id)
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                "No files associated with this AI Assistant"
              )}
            </Grid>
          </>
        )}
      </Grid>
    </React.Fragment>
  );
}
