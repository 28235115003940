import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Grid,
  Button,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Box,
  RadioGroup,
  IconButton,
  FormControlLabel,
  Radio,
  TextField as MuiTextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ListSubheader
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ShareIcon from '@mui/icons-material/Share';
import { v4 as uuidv4 } from 'uuid';
import * as signalR from '@microsoft/signalr';
import api from '../../Services/api';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, CardHeader } from '@mui/material';
import { height, padding } from '@mui/system';
import { HomeAppContext } from "../Home";
import Lottie from 'lottie-react';
import waitingAnimation from '../../waitingAnimation.json';
import NavigationIcon from '@mui/icons-material/Navigation';
import { Tabs, Tab } from '@mui/material';

const ChatPage = () => {
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [editingConversationId, setEditingConversationId] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [conversationToDelete, setConversationToDelete] = useState(null);
  const [selectedAiEngine, setSelectedAiEngine] = useState('');
  const [selectedConversationTitle, setSelectedConversationTitle] = useState(null);
  const [aiEngines, setAiEngines] = useState([]);
  const [userId, setUserId] = useState(null);
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const [groupedEngines, setGroupedEngines] = useState([]);
  const [engineFamilies, setEngineFamilies] = useState([]);
  const [selectedEngineFamily, setSelectedEngineFamily] = useState('');
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [shareType, setShareType] = useState(0);
  const [conversationToShare, setConversationToShare] = useState(null);
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const fetchFilteredConversations = async () => {
      try {
        const filterOptions = ["all", "my", "shared"];
        const response = await api.get(`/api/chatbot/GetHistory`, {
          params: { filter: filterOptions[activeTab] },
        });
        setConversations(response.data.history);
        setUserId(response.data.userId);
      } catch (error) {
        console.error('Error fetching filtered conversations:', error);
      }
    };
  
    fetchFilteredConversations();
  }, [activeTab]);

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };
  
  const handleOptionChange = (event) => {
    setShareType(event.target.value);
  };  

  const handleOpenShareDialog = (id, currentShareType) => {
    setConversationToShare(id);
    setShareType(currentShareType);
    setOpenShareDialog(true);
  };
  
  const handleCloseShareDialog = () => {
    setOpenShareDialog(false);
    setConversationToShare(null);
  };
  
  const handleConfirmShareType = async () => {
    try {
      const sharingChatbotDto = {
        sharingId: conversationToShare,
        sharingOption: shareType,
      };

      await api.put('/api/chatbot/UpdateShareType', sharingChatbotDto);
  
      setConversations((prev) =>
        prev.map((conv) =>
          conv.id === conversationToShare ? { ...conv, shareType } : conv
        )
      );
      handleCloseShareDialog();
    } catch (error) {
      console.error('Error updating share type:', error);
    }
  };

  const theme = useTheme();
  const messageEndRef = useRef(null);
  const messageInputRef = useRef(null);

  const aiMessageIndexRef = useRef(null);
  const connectionRef = useRef(null);
  const selectedConversationIdRef = useRef(selectedConversationId);

  const isConversationEmpty = (conversation) => {
    return (
      !conversation.messages ||
      conversation.messages.length === 0 ||
      conversation.messages.every((msg) => !msg.text.trim())
    );
  };

  const removeEmptyConversations = () => {
    setConversations((prevConversations) =>
      prevConversations.filter((conv) => !isConversationEmpty(conv))
    );
  };  

  const handleCancelEdit = () => {
    setEditingConversationId(null);
    setEditedTitle('');
  };  

  React.useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "Chat with AI"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  useEffect(() => {
    const fetchDefaultEngine = async () => {
      try {
        const familyDto = {
          name: "ChatGPT",
        };

        const response = await api.post('/api/ai-engines/default', familyDto);
        const defaultEngine = response.data;

        if (defaultEngine) {
          setSelectedEngineFamily(defaultEngine.engineFamily);
          setSelectedAiEngine(defaultEngine.id);
        }
      } catch (error) {
        console.error('Error fetching default AI engine:', error);
      }
    };

    const fetchAllEngines = async () => {
      try {
        const response = await api.get('/api/ai-engines/ListActives');
        const engines = response.data.items;
        setAiEngines(engines);

        const grouped = engines.reduce((acc, engine) => {
          const family = engine.engineFamily;
          if (!acc[family]) acc[family] = [];
          acc[family].push(engine);
          return acc;
        }, {});

        setGroupedEngines(grouped);
        setEngineFamilies(Object.keys(grouped));
      } catch (error) {
        console.error('Error fetching AI engines:', error);
      }
    };

    fetchDefaultEngine();
    fetchAllEngines();
  }, []); 

  useEffect(() => {
    selectedConversationIdRef.current = selectedConversationId;
  }, [selectedConversationId]);

  const getSharingOption = (shareType) => {
    switch (shareType) {
      case 0: return 'private';
      case 1: return 'public-read-only';
      case 2: return 'public';
    }
};

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_BACKEND_URL + "/chathub")
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then(() => {
        console.log('Connected to SignalR hub');      

        connection.on('ReceiveAiResponse', (messagePart) => {
          setConversations((prevConversations) => {
            const updatedConversations = prevConversations.map((conv) => {
              if (conv.id === selectedConversationIdRef.current) {
                const updatedMessages = [...conv.messages];
                const aiMessageIndex = aiMessageIndexRef.current;
                if (
                  aiMessageIndex !== null &&
                  updatedMessages[aiMessageIndex]
                ) {
                  updatedMessages[aiMessageIndex].text += messagePart;
                }
                return { ...conv, messages: updatedMessages };
              }
              return conv;
            });
            return updatedConversations;
          });
  
          if (messageInputRef.current) {
            messageInputRef.current.blur();
            setTimeout(() => {
              messageInputRef.current.focus({ preventScroll: true });
              messageInputRef.current.setSelectionRange(
                messageInputRef.current.value.length,
                messageInputRef.current.value.length
              );
            }, 50);
          }
        });
      })
      .catch((error) => console.error('SignalR Connection Error: ', error));

      connection.on('SetConversationId', (conversationId) => {
        setSelectedConversationId(conversationId);
        selectedConversationIdRef.current = conversationId;
      
        setConversations((prevConversations) => {
          return prevConversations.map((conv) => {
            if (conv.id === null) {
              return { ...conv, id: conversationId };
            }
            return conv;
          });
        });
      });  

    connectionRef.current = connection;

    return () => {
      if (connectionRef.current) {
        connectionRef.current.stop();
      }
    };
  }, []);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    setNewMessage('');
    setLoading(true);
    try {
      const userMessage = { role: 'user', text: newMessage };
      const aiMessage = { role: 'ai', text: '' };

      if (!selectedConversationId) {
        const newConversationId = uuidv4();
        const firstPrompt = newMessage.trim();
        let truncatedTitle = firstPrompt.substring(0, 22);

        if (firstPrompt.length > 22) {
          const lastSpaceIndex = truncatedTitle.lastIndexOf(' ');
          if (lastSpaceIndex > 0) {
            truncatedTitle = truncatedTitle.substring(0, lastSpaceIndex);
          }
        }

        const newConversation = {
          id: newConversationId,
          title: truncatedTitle || `Conversation ${newConversationId.substring(0, 8)}`,
          messages: [userMessage, aiMessage],
          aiEngine: selectedAiEngine,
          lastActivity: new Date().toISOString(),
        };

        setConversations([newConversation, ...conversations]);
        setSelectedConversationId(newConversation.id);
        setSelectedConversationTitle(newConversation.title);

        aiMessageIndexRef.current = 1;

        await connectionRef.current.invoke('StartChat', {
          newMessage: newMessage,
          aiEngine: selectedAiEngine,
          conversationId: newConversation.id,
          role: 'user',
          conversationTitle: newConversation.title,
          aiEngine: selectedAiEngine,
          userId: userId,
        });
      } else {
        let aiMessageIndex;
  
        const selectedConversation = conversations.find(
          (conv) => conv.id === selectedConversationId
        );
  
        if (selectedConversation.aiEngine !== selectedAiEngine) {
          const newConversationId = uuidv4();
          const firstPrompt = newMessage.trim();
          let truncatedTitle = firstPrompt.substring(0, 22);
  
          if (firstPrompt.length > 22) {
            const lastSpaceIndex = truncatedTitle.lastIndexOf(' ');
            if (lastSpaceIndex > 0) {
              truncatedTitle = truncatedTitle.substring(0, lastSpaceIndex);
            }
          }

          const newConversation = {
            id: newConversationId,
            title: truncatedTitle || `Conversation ${newConversationId.substring(0, 8)}`,
            messages: [userMessage, aiMessage],
            aiEngine: selectedAiEngine,
            lastActivity: new Date().toISOString()
          };
  
          setConversations([newConversation, ...conversations]);
          setSelectedConversationId(newConversation.id);
          setSelectedConversationTitle(newConversation.title);
  
          aiMessageIndexRef.current = 1;
  
          await connectionRef.current.invoke('StartChat', {
            newMessage: newMessage,
            aiEngine: selectedAiEngine,
            conversationId: newConversation.id,
            role: 'user',
            conversationTitle: newConversation.title,
            userId: userId,
          });
        } else {
        let aiMessageIndex;
        setConversations((prevConversations) => {
          const updatedConversations = prevConversations.map((conv) => {
            if (conv.id === selectedConversationId) {
              const updatedMessages = [...conv.messages, userMessage, aiMessage];
              aiMessageIndex = updatedMessages.length - 1;
              return { ...conv, messages: updatedMessages, lastActivity: new Date().toISOString() };
            }
            return conv;
          });
          const updatedConversation = updatedConversations.find((conv) => conv.id === selectedConversationId);
          const otherConversations = updatedConversations.filter((conv) => conv.id !== selectedConversationId);
      
          aiMessageIndexRef.current = aiMessageIndex;
          return [updatedConversation, ...otherConversations];
        });

        await connectionRef.current.invoke('StartChat', {
          newMessage: newMessage,
          aiEngine: selectedAiEngine,
          conversationId: selectedConversationId,
          role: 'user',
          conversationTitle: selectedConversationTitle,
          userId: userId,
        });
      }
    }

      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectConversation = (conversationId) => {
    const selectedConversation = conversations.find(
      (conv) => conv.id === conversationId
    );
    if (selectedConversation) {
      setSelectedConversationId(conversationId);
      setSelectedConversationTitle(selectedConversation.title);
      setSelectedAiEngine(selectedConversation.aiEngine);
      console.log('Selected conversation messages:', selectedConversation.messages);
    } else {
      console.error('Conversation not found');
    }
  };

  const handleNewConversation = async () => {
    try {
      const familyDto = {
        name: selectedEngineFamily,
      };  

      const response = await api.post(`/api/ai-engines/default`, familyDto);
      const defaultEngine = response.data;
  
      if (defaultEngine) {
        setSelectedAiEngine(defaultEngine.id);
      }
  
      setSelectedConversationId(null);
      setSelectedConversationTitle(null);
  
    } catch (error) {
      console.error('Error setting default engine for new conversation:', error);
    }
  }; 

  const handleEditConversation = (id, title) => {
    setEditingConversationId(id);
    setEditedTitle(title);
  };

  const handleSaveConversationTitle = async (id) => {
    try {
      const chatbotPut = {
        conversationId: id,
        conversationTitle: editedTitle,
      };

      await api.post(`/api/chatbot/UpdateConversationTitle`, chatbotPut);
  
      const updatedConversations = conversations.map((conv) =>
        conv.id === id ? { ...conv, title: editedTitle } : conv
      );
      setConversations(updatedConversations);
      setEditingConversationId(null);
    } catch (error) {
      console.error('Error updating conversation title:', error);
    }
  };  

  const handleOpenDeleteDialog = (id) => {
    setOpenDeleteDialog(true);
    setConversationToDelete(id);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setConversationToDelete(null);
  };

  const handleConfirmDeleteConversation = async () => {
    try {
      const chatbotDelete = {
        conversationId: conversationToDelete,
      };

      await api.post(`/api/chatbot/DeleteConversation`, chatbotDelete);
  
      const updatedConversations = conversations.filter(
        (conv) => conv.id !== conversationToDelete
      );
      setConversations(updatedConversations);
      if (selectedConversationId === conversationToDelete) {
        setSelectedConversationId(null);
      }
      handleCloseDeleteDialog();
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };  

  const handleEngineFamilyChange = async (event) => {
    const selectedFamily = event.target.value;
    setSelectedEngineFamily(selectedFamily);
  
    try {
      const familyDto = {
        name: event.target.value,
      };  

      const response = await api.post(`/api/ai-engines/default`, familyDto);
      const defaultEngine = response.data;
  
      if (defaultEngine) {
        setSelectedAiEngine(defaultEngine.id);
      }
    } catch (error) {
      console.error('Error fetching default AI engine for family:', error);
    }
  };
  

  const handleAiEngineChange = (event) => {
    setSelectedAiEngine(event.target.value);

    setSelectedConversationId(null);
    setSelectedConversationTitle(null);
  };

  const styles = {
    container: {
      display: 'flex',
      overflow: 'hidden',
    },
    leftSide: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      boxSizing: 'border-box',
    },
    rightSide: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      boxSizing: 'border-box',
    },
    chatArea: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 120px)',
      },
    },
    chatBox: {
      flex: 1,
      overflowY: 'auto',
      borderRadius: '10px',
      backgroundColor: theme.palette.mode === 'dark' ? '#333' : theme.palette.background.paper,
      boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
      padding: theme.spacing(2),
      maxHeight: '64vh',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '40vh',
      },
    },
    messageRow: (isUser) => ({
      display: 'flex',
      justifyContent: isUser ? 'flex-end' : 'flex-start',
      marginBottom: '10px',
    }),
    messageBubble: (isUser) => ({
      maxWidth: '70%',
      padding: '15px',
      borderRadius: '15px',
      backgroundColor: isUser
        ? theme.palette.primary.main
        : theme.palette.background.default,
      color: isUser ? '#fff' : theme.palette.text.primary,
      boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
      whiteSpace: 'pre-wrap',
    }),
    inputArea: {
      display: 'flex',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: '8px',
      alignItems: 'center',
      boxShadow: theme.palette.mode === 'light' ? '0px 4px 10px rgba(0,0,0,0.1)' : 'none',
    },
    sendButton: {
      marginLeft: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      height: 58,
      width: 38,
      borderRadius: '50%',
    },
    aiEngineCard: {
      marginBottom: '20px',
    },
    historyList: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto',
      maxHeight: '42vh',
      boxShadow: theme.palette.mode === 'light' ? '0px 4px 10px rgba(0,0,0,0.1)' : 'none',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '30vh',
      },
    },
    historyListHead: {
      padding: '10px',
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
      boxShadow: theme.palette.mode === 'light' ? '0px 4px 10px rgba(0,0,0,0.1)' : 'none',
    },
    newConversationButton: {
      marginTop: '10px',
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    selectAiEngine: {
      marginBottom: '20px',
    },
    card: {
      marginBottom: theme.spacing(2),
    },
  };

  const selectedConversation = conversations.find(
    (conv) => conv.id === selectedConversationId
  );
  const messages = selectedConversation ? selectedConversation.messages : [];
  const isReadOnly = selectedConversation?.userId !== homeState.userProfile.userId && selectedConversation?.shareType === 1;

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [selectedConversationId, messages]);  

  return (
    <>
      <Grid container spacing={0} style={styles.container}>
        {/* AI Engine Selection */}



        {/* Chat Area */}
        <Grid item xs={12} md={8} style={styles.leftSide}>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Paper style={styles.chatBox}>
  {messages.length === 0 ? (
    <Box style={styles.animationContainer}>
      <Lottie animationData={waitingAnimation} loop={true} />
      <Typography variant="h6" align="center" style={{ marginTop: '20px' }}>
        Waiting for your prompt...
      </Typography>
    </Box>
  ) : (
    messages.map((msg, idx) => (
      <Box
        key={idx}
        style={styles.messageRow(msg.role === 'user' || msg.role === 'User')}
      >
        <Box
          style={styles.messageBubble(msg.role === 'user' || msg.role === 'User')}
        >
          {msg.text}
        </Box>
      </Box>
    ))
  )}
  <div ref={messageEndRef} />
</Paper>

          </Box>
          <Box style={styles.inputArea}>
            
            <TextField
              variant="outlined"
              placeholder = {isReadOnly ? "This prompt is public read-only, so you cannot edit it." : "Ask me anything..."}
              fullWidth
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              multiline
              rows={3}
              disabled={loading || isReadOnly} 
              inputRef={messageInputRef}
            />
            <Button
              variant="contained"
              disabled={loading || isReadOnly}
              style={styles.sendButton}
              onClick={handleSendMessage}
            >
  {loading ? (
    <CircularProgress size={24} color="inherit" />
  ) : (
    <NavigationIcon />
  )}
            </Button>
          </Box>
        </Grid>

        {/* AI Engine Selection */}
        <Grid item xs={12} md={4} style={styles.rightSide}>
        <Card style={{ marginBottom: '20px' }}>
          <CardContent>
            <FormControl fullWidth>
              <InputLabel>Select AI Engine Family</InputLabel>
              <Select
                value={selectedEngineFamily}
                onChange={handleEngineFamilyChange}
                label="Select AI Engine Family"
              >
                {engineFamilies.map((family) => (
                  <MenuItem key={family} value={family}>
                    {family}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth disabled={!selectedEngineFamily} style={{ marginTop: '20px' }}>
              <InputLabel>Select AI Engine</InputLabel>
              <Select
                value={selectedAiEngine}
                onChange={handleAiEngineChange}
                label="Select AI Engine"
              >
                {selectedEngineFamily &&
                  groupedEngines[selectedEngineFamily]?.map((engine) => (
                    <MenuItem key={engine.id} value={engine.id}>
                      {engine.engineName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </CardContent>
        </Card>

        {/* Conversation History */}

        <FormControl 
        style={styles.historyListHead}
        fullWidth>
          
          <Typography variant="h6" gutterBottom>
            Conversation History
          </Typography>
  <Tabs
  value={activeTab}
  onChange={handleTabChange}
  indicatorColor="primary"
  textColor="primary"
  variant="fullWidth"
>
  <Tab label="All" />
  <Tab label="Created by Me" />
  <Tab label="Shared with Me" />
</Tabs>
</FormControl>

          <List style={styles.historyList}>
            {conversations.map((conv) => (
              <ListItem
                button
                key={conv.id}
                onClick={() => handleSelectConversation(conv.id)}
                style={{
                  backgroundColor:
                    selectedConversationId === conv.id
                      ? theme.palette.primary.main
                      : '',
                  borderRadius: '5px',
                  color: selectedConversationId === conv.id ? '#fff' : '',
                }}
              >
                {editingConversationId === conv.id ? (
                  <>
                    <MuiTextField
                      value={editedTitle}
                      onChange={(e) => setEditedTitle(e.target.value)}
                      size="small"
                    />
<IconButton
  onClick={() => handleSaveConversationTitle(conv.id)}
  size="small"
>
  <CheckIcon fontSize="small" />
</IconButton>
<IconButton onClick={handleCancelEdit} size="small">
  <CloseIcon fontSize="small" />
</IconButton>

                  </>
                ) : (
                  <>
<ListItemText
  primary={conv.title || `Conversation ${conv.id}`}
  secondary={`${
    conv.lastActivity
      ? new Date(conv.lastActivity).toLocaleDateString()
      : new Date().toLocaleDateString()
  } - ${
    aiEngines.find((engine) => engine.id === conv.aiEngine)?.engineName || 'Unknown'
  }`}
/>

                    {((conv.userId === homeState.userProfile.userId) || (conv.userId !== homeState.userProfile.userId && conv.shareType === 2)) && (
                    <IconButton
                      onClick={() => handleEditConversation(conv.id, conv.title)}
                    >
                      <EditIcon />
                    </IconButton>
                    )}
                    {conv.userId === homeState.userProfile.userId && (
                    <IconButton onClick={() => handleOpenDeleteDialog(conv.id)}>
                      <DeleteIcon />
                    </IconButton>
                    )}
                    {conv.userId === homeState.userProfile.userId && (
                      <IconButton onClick={() => handleOpenShareDialog(conv.id, conv.shareType)}>
                        <ShareIcon />
                      </IconButton>
                    )}
                  </>
                )}
              </ListItem>
            ))}
          </List>

          <Button
            variant="contained"
            style={styles.newConversationButton}
            onClick={handleNewConversation}
          >
            New Conversation
          </Button>
        </Grid>
      </Grid>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this conversation? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDeleteConversation} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openShareDialog} onClose={handleCloseShareDialog}>
      <DialogTitle>Share Settings</DialogTitle>
      <DialogContent>
        <Typography>Select the sharing option:</Typography>
        <RadioGroup
          value={shareType}
          onChange={handleOptionChange}
          aria-label="sharing options"
        >
          <FormControlLabel value={0} control={<Radio />} label="Private" />
          <Box pl={4} mb={2}>
            <Typography variant="body2" color="textSecondary">
              This is the default state and means that nobody else can see this conversation, other than the creator.
            </Typography>
          </Box>

          <FormControlLabel value={1} control={<Radio />} label="Public Read-Only" />
          <Box pl={4} mb={2}>
            <Typography variant="body2" color="textSecondary">
              This conversation is shared, but only the creator can edit it.
            </Typography>
          </Box>

          <FormControlLabel value={2} control={<Radio />} label="Public" />
          <Box pl={4} mb={2}>
            <Typography variant="body2" color="textSecondary">
              This conversation can be edited by anyone. When they save it, all changes will be saved back and available to all users.
            </Typography>
          </Box>
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseShareDialog} color="secondary">Cancel</Button>
        <Button onClick={handleConfirmShareType} color="primary">Save</Button>
      </DialogActions>
    </Dialog>

    </>
  );
};

export default ChatPage;
